<template>
  <v-container :style="$vuetify.breakpoint.md ? 'max-width: 950px' : ''">
    <base-material-card
      id="box-list"
      :title="$t('list', { name: $tc('customer', 2) })"
      icon="mdi-account"
    >
      <v-row
        width="100%"
        :style="
          $vuetify.breakpoint.xsOnly
            ? 'position:fixed;background:var(--v-gris3-base); top: 60px;z-index: 10'
            : ''
        "
        class="mt-3"
      >
        <v-row justify="start" class="pl-3" style="padding-top: 6px">
          <v-col
            cols="2"
            sm="1"
            md="1"
            lg="1"
            class="py-0 mr-md-3 mr-sm-3 mr-lg-3"
          >
            <v-menu :close-on-content-click="true">
              <template v-slot:activator="{ on }">
                <v-btn
                  elevation="0"
                  icon
                  :disabled="
                    ($store.getters['auth/isTattooer'] && boxes.length === 0) ||
                      !can_reservate
                  "
                  v-on="on"
                  style="height: 30px; margin-top: 5px; order: 1"
                >
                  <v-icon size="36">mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="openBox()"
                  v-if="$store.getters['auth/isStudio']"
                >
                  <v-list-item-icon class="mr-3 my-md-2 my-2">
                    <v-icon small>mdi-plus-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("box") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="reserva((actRes = true))"
                  v-if="$store.getters['auth/isStudio']"
                >
                  <v-list-item-icon class="mr-3 my-md-2 my-2">
                    <v-icon small>mdi-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("boxes.reservar") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="$store.getters['auth/isStudio'] && false"
                  @click="
                    $router.push({
                      name: 'new_reservation_box',
                      params: {
                        token: $store.state.auth.user.studio.token,
                      },
                    })
                  "
                >
                  <v-list-item-icon class="mr-3 my-md-2 my-2">
                    <v-icon small> $request </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("tattooers.inscription") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-else-if="$store.getters['auth/isTattooer']"
                  @click="
                    $router.push({
                      name: 'new_reservation_box',
                      params: {
                        token: studios.find((x) => x.id === studio_id).token,
                      },
                    })
                  "
                >
                  <v-list-item-icon class="mr-3 my-md-2 my-2">
                    <v-icon small> $request </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("boxes.reservar") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="resDialog = true"
                  v-if="$store.getters['auth/isStudio']"
                >
                  <v-list-item-icon class="mr-3 my-md-2 my-2">
                    <v-icon small>mdi-link-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> Link</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  @click="goToCalendar()"
                  v-if="$store.getters['auth/isStudio']"
                >
                  <v-list-item-icon class="mr-3 my-md-2 my-2">
                    <v-icon small>$calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("appointments.ask") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-dialog width="300" v-model="resDialog">
              <v-card id="pedirReservaLink">
                <v-card-title
                  style="text-decoration: underline; text-transform: uppercase"
                >
                  Link pedir reserva:
                </v-card-title>
                <div class="close">
                  <v-btn icon @click="resDialog = false">
                    <v-icon id="iconClose">mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-text>
                  <v-text-field outlined dense id="URL" readonly v-model="URL">
                    <template slot="prepend-inner">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon class="mr-2" v-on="on" @click="copy()">
                            mdi-content-copy
                          </v-icon>
                        </template>
                        {{ $t("copy") }}
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col
            :style="
              $vuetify.breakpoint.xsOnly && $store.getters['auth/isTattooer']
                ? 'order: 2'
                : 'max-width:970px; margin-left: -15px;'
            "
            cols="5"
            md="3"
            lg="2"
            v-if="tab == false"
            class="py-0 px-2"
            id="time"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              transition="scale-transition"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  data-vv-name="startDate"
                  :label="$t('events.date')"
                  readonly
                  class="filters"
                  v-bind="attrs"
                  v-on="on"
                  :value="date ? $d(new Date(date), 'date2digits') : ''"
                  ><template v-slot:prepend-inner>
                    <!--Aqui va el v-icon -->
                    <v-icon
                      small
                      class="pt-0 pb-1"
                      style="margin-top: 5px"
                      :color="edit == false ? 'gris1' : 'primary'"
                      >$appointments</v-icon
                    >
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="date"
                no-title
                scrollable
                color="primary"
                @input="
                  menu = false;
                  reload();
                "
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            class="ml-1 pt-0 pr-0"
            v-if="$store.getters['auth/isTattooer'] && !tab"
            cols="11"
            sm="6"
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'display:flex; justify-content: center; order: 4'
                : ''
            "
            md="2"
            align="center"
          >
            <div>
              <v-select
                :items="studios"
                v-model="studio_id"
                item-value="id"
                item-text="studio_name"
                dense
                id="selectionsTattooer"
                outlined
                hide-details
                :label="$tc('studio')"
                data-vv-validate-on="blur"
                style="display: inline-block"
              ></v-select>
            </div>
          </v-col>
          <v-col
            cols="3"
            class="pa-0"
            :style="
              $store.getters['auth/isTattooer']
                ? !tab
                  ? 'margin-top: -2px; order: 3; margin-left: 20px'
                  : 'margin-top: -2px; order: 3;  '
                : 'margin-top: -2px; margin-left: 40px '
            "
            v-if="$vuetify.breakpoint.xsOnly && !tab"
          >
            <v-row justify="end"
              ><v-col cols="4" md="2" class="mr-1 pt-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      value="list"
                      fab
                      elevation="0"
                      v-bind="attrs"
                      v-on="on"
                      :outlined="tab == false"
                      :disabled="totalBoxes === 0"
                      @click="tab = true"
                      style="height: 40px; width: 40px"
                    >
                      <v-icon size="20">$square</v-icon>
                    </v-btn></template
                  >
                  <span>{{ $t("table") }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="4"
                md="2"
                style="padding-top: 0px; margin-right: 5px"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      elevation="0"
                      v-bind="attrs"
                      v-on="on"
                      :outlined="tab == true"
                      @click="
                        tab = false;
                        fetchStatusList();
                        boxesHoursDay();
                      "
                      style="height: 40px; width: 40px"
                    >
                      <v-icon size="20">$list</v-icon>
                    </v-btn></template
                  >
                  <span>{{ $t("boxes.list") }}</span>
                </v-tooltip>
              </v-col></v-row
            >
          </v-col>
          <v-col
            :style="
              btn == 'week'
                ? $vuetify.breakpoint.xsOnly
                  ? 'max-width:970px; margin-left: -15px; margin-top: -2px'
                  : 'max-width:970px; margin-left: -15px; margin-top: -2px'
                : btn == 'day'
                ? 'max-width:970px; margin-left: -15px; margin-top: -2px'
                : 'max-width:970px; margin-left: -15px; margin-top: -2px'
            "
            class="pt-0 pl-0"
            cols="10"
            md="11"
            v-if="tab == true"
          >
            <v-row>
              <v-btn-toggle
                :class="
                  $store.getters['auth/isTattooer'] &&
                  $vuetify.breakpoint.xsOnly
                    ? 'ml-10'
                    : 'pl-5  pl-md-5 mr-sm-3 mr-lg-3 '
                "
                style="margin-top: 5.5px"
                mandatory
                :value="btn"
              >
                <v-menu
                  v-model="menu1.day"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="btn == 'week'"
                      value="day"
                      outlined
                      height="30px"
                      @click="btn = 'day'"
                      :width="$vuetify.breakpoint.xsOnly ? '60px' : '80px'"
                      >{{ $t("day") }}</v-btn
                    >
                    <v-btn
                      v-on="on"
                      v-else
                      value="day"
                      class="selected"
                      outlined
                      v-bind="attrs"
                      height="30px"
                      @click="btn = 'day'"
                      :width="$vuetify.breakpoint.xsOnly ? '60px' : '80px'"
                      >{{ $t("day") }}</v-btn
                    >
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    no-title
                    v-model="date"
                    @input="
                      menu1.day = false;
                      fetchTable();
                    "
                    color="primary"
                  ></v-date-picker>
                </v-menu>

                <v-menu
                  v-model="menu1.week"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="btn == 'day'"
                      value="week"
                      active-class="selected"
                      outlined
                      height="30px"
                      @click="
                        fetchWeek();
                        btn = 'week';
                      "
                      :width="$vuetify.breakpoint.xsOnly ? '60px' : '80px'"
                      >{{ $t("week") }}</v-btn
                    >
                    <v-btn
                      v-on="on"
                      v-else
                      value="week"
                      class="selected"
                      outlined
                      v-bind="attrs"
                      height="30px"
                      @click="btn = 'week'"
                      :width="$vuetify.breakpoint.xsOnly ? '60px' : '80px'"
                      >{{ $t("week") }}</v-btn
                    >
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    no-title
                    v-model="week"
                    @input="
                      menu1.week = false;
                      fetchWeek();
                    "
                    color="primary"
                    range
                    class="weekSelector"
                  ></v-date-picker>
                </v-menu>
              </v-btn-toggle>
              <!--Format mobil-->
              <v-col
                cols="3"
                v-if="
                  btn === 'day' &&
                    $vuetify.breakpoint.xsOnly &&
                    $store.getters['auth/isStudio']
                "
                class="py-0 pr-0 ml-3 ml-sm-0"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1 mt-1"
                      value="list"
                      fab
                      outlined
                      elevation="0"
                      v-bind="attrs"
                      v-on="on"
                      @click="tab_day = true"
                      :style="
                        tab_day
                          ? 'height: 31px; width: 31px; border: 3px solid var(--v-primary-base); margin-top: 5px'
                          : 'height: 30px; width: 31px; margin-top: 5px'
                      "
                    >
                      <v-icon size="15">$customers</v-icon>
                    </v-btn></template
                  >
                  <span>{{ $tc("appointment") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      outlined
                      elevation="0"
                      v-bind="attrs"
                      v-on="on"
                      @click="tab_day = false"
                      :style="
                        !tab_day
                          ? 'height: 31px; width: 31px; border: 3px solid var(--v-primary-base); margin-top: 5px'
                          : 'height: 30px; width: 31px; margin-top: 5px'
                      "
                    >
                      <v-icon size="15">$tattooers</v-icon>
                    </v-btn></template
                  >
                  <span>{{ $tc("artist") }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="3"
                md="2"
                v-if="
                  btn == 'day' &&
                    $vuetify.breakpoint.xsOnly &&
                    $store.getters['auth/isStudio']
                "
                class="pa-0 ml-n1 ml-sm-0"
                id="time"
              >
                <!-- Buttons lista , tabla formato movil-->
                <v-row justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-1"
                        value="list"
                        fab
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        :outlined="tab == false"
                        @click="tab = true"
                        :disabled="totalBoxes === 0"
                        style="height: 40px; width: 40px"
                      >
                        <v-icon size="20">$square</v-icon>
                      </v-btn></template
                    >
                    <span>{{ $t("table") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        :outlined="tab == true"
                        @click="
                          tab = false;
                          fetchStatusList();
                          boxesHoursDay();
                        "
                        style="height: 40px; width: 40px"
                      >
                        <v-icon size="20">$list</v-icon>
                      </v-btn></template
                    >
                    <span>{{ $t("boxes.list") }}</span>
                  </v-tooltip>
                </v-row>
                <!--Fin buttons lista , tabla formato movil-->
              </v-col>
              <v-col
                cols="3"
                md="2"
                v-if="
                  btn == 'day' &&
                    $vuetify.breakpoint.xsOnly &&
                    $store.getters['auth/isTattooer']
                "
                class="pa-0 ml-n1 ml-sm-0"
                style="margin-left: 60px !important"
                id="time"
              >
                <!-- Buttons lista , tabla formato movil ARTISTA-->
                <v-row justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-1"
                        value="list"
                        fab
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        :outlined="tab == false"
                        @click="tab = true"
                        :disabled="totalBoxes === 0"
                        style="height: 40px; width: 40px"
                      >
                        <v-icon size="20">$square</v-icon>
                      </v-btn></template
                    >
                    <span>{{ $t("table") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        :outlined="tab == true"
                        @click="
                          tab = false;
                          fetchStatusList();
                          boxesHoursDay();
                        "
                        style="height: 40px; width: 40px"
                      >
                        <v-icon size="20">$list</v-icon>
                      </v-btn></template
                    >
                    <span>{{ $t("boxes.list") }}</span>
                  </v-tooltip>
                </v-row>
                <!--Fin buttons lista , tabla formato movil-->
              </v-col>
              <!--fin Format mobil-->
              <v-col
                cols="5"
                sm="3"
                md="2"
                v-if="btn == 'day'"
                class="py-0 px-2 ml-2 mt-2 mt-sm-0 mt-md-0"
                id="time"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'required'"
                      data-vv-name="startDate"
                      :label="$t('events.date')"
                      readonly
                      class="filters"
                      v-bind="attrs"
                      v-on="on"
                      :value="date ? $d(new Date(date), 'date2digits') : ''"
                      ><template v-slot:prepend-inner>
                        <!--Aqui va el v-icon -->
                        <v-icon
                          small
                          class="pt-0 pb-1"
                          style="margin-top: 5px"
                          :color="edit == false ? 'gris1' : 'primary'"
                          >$appointments</v-icon
                        >
                      </template></v-text-field
                    >
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="date"
                    no-title
                    scrollable
                    color="primary"
                    @input="
                      menu = false;
                      fetchTable();
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                v-if="
                  btn === 'day' &&
                    !$vuetify.breakpoint.xsOnly &&
                    $store.getters['auth/isStudio']
                "
                class="py-0"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1 mt-1"
                      value="list"
                      fab
                      outlined
                      elevation="0"
                      v-bind="attrs"
                      v-on="on"
                      @click="tab_day = true"
                      :style="
                        tab_day
                          ? 'height: 31px; width: 31px; border: 3px solid var(--v-primary-base); margin-top: 5px'
                          : 'height: 30px; width: 31px; margin-top: 5px'
                      "
                    >
                      <v-icon size="15">$customers</v-icon>
                    </v-btn></template
                  >
                  <span>{{ $tc("appointment") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      outlined
                      elevation="0"
                      v-bind="attrs"
                      v-on="on"
                      @click="tab_day = false"
                      :style="
                        !tab_day
                          ? 'height: 31px; width: 31px; border: 3px solid var(--v-primary-base); margin-top: 5px'
                          : 'height: 30px; width: 31px; margin-top: 5px'
                      "
                    >
                      <v-icon size="15">$tattooers</v-icon>
                    </v-btn></template
                  >
                  <span>{{ $tc("artist") }}</span>
                </v-tooltip>
              </v-col>
              <!--Format mobil-->
              <v-col
                cols="3"
                v-if="
                  tab == true &&
                    btn == 'week' &&
                    $vuetify.breakpoint.xsOnly &&
                    $store.getters['auth/isStudio']
                "
                class="py-0 pr-0 ml-3 ml-sm-0"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1 mt-1"
                      value="list"
                      fab
                      outlined
                      elevation="0"
                      v-bind="attrs"
                      v-on="on"
                      @click="tab_week = true"
                      :style="
                        tab_week
                          ? 'height: 31px; width: 31px; border: 3px solid var(--v-primary-base); margin-top: 5px'
                          : 'height: 30px; width: 31px; margin-top: 5px'
                      "
                    >
                      <v-icon size="15">$calendar</v-icon>
                    </v-btn></template
                  >
                  <span>{{ $t("calendar") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      outlined
                      elevation="0"
                      v-bind="attrs"
                      v-on="on"
                      @click="tab_week = false"
                      :style="
                        !tab_week
                          ? 'height: 31px; width: 31px; border: 3px solid var(--v-primary-base); margin-top: 5px'
                          : 'height: 30px; width: 31px; margin-top: 5px'
                      "
                    >
                      <v-icon size="15">mdi-percent</v-icon>
                    </v-btn></template
                  >
                  <span>{{ $t("boxes.percentage") }}</span>
                </v-tooltip> </v-col
              ><v-col
                cols="3"
                md="2"
                v-if="btn == 'week' && $vuetify.breakpoint.xsOnly"
                class="pa-0 ml-sm-0 ml-1"
                :style="
                  $store.getters['auth/isTattooer']
                    ? 'margin-left: 60px !important'
                    : 'margin-left: -5px !important'
                "
                id="time"
              >
                <!-- Buttons lista , tabla formato movil-->
                <v-row justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-1"
                        value="list"
                        fab
                        elevation="0"
                        v-bind="attrs"
                        :disabled="totalBoxes === 0"
                        v-on="on"
                        :outlined="tab == false"
                        @click="tab = true"
                        style="height: 40px; width: 40px"
                      >
                        <v-icon size="20">$square</v-icon>
                      </v-btn></template
                    >
                    <span>{{ $t("table") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        :outlined="tab == true"
                        @click="
                          tab = false;
                          fetchStatusList();
                          boxesHoursDay();
                        "
                        style="height: 40px; width: 40px"
                      >
                        <v-icon size="20">$list</v-icon>
                      </v-btn></template
                    >
                    <span>{{ $t("boxes.list") }}</span>
                  </v-tooltip>
                </v-row>
                <!--Fin buttons lista , tabla formato movil-->
              </v-col>
              <v-col
                class="ml-1 mt-2 mt-sm-0 pt-0 pr-0"
                v-if="
                  $store.getters['auth/isTattooer'] &&
                    $vuetify.breakpoint.xsOnly
                "
                cols="6"
                md="5"
              >
                <v-select
                  :items="studios"
                  v-model="studio_id"
                  item-value="id"
                  item-text="studio_name"
                  dense
                  id="selectionsTattooer"
                  outlined
                  hide-details
                  :label="$tc('studio')"
                  data-vv-validate-on="blur"
                  style="display: inline-block"
                ></v-select
              ></v-col>
              <!--fin Format mobil-->
              <v-row
                class="mx-0"
                v-if="
                  ($store.getters['auth/isTattooer'] &&
                    !$vuetify.breakpoint.xsOnly) ||
                    $store.getters['auth/isStudio']
                "
              >
                <v-col
                  class="pt-md-0 pl-0 pl-md-3"
                  v-if="
                    $store.getters['auth/isTattooer'] &&
                      !$vuetify.breakpoint.xsOnly
                  "
                  cols="6"
                  md="4"
                  style="max-width: 220px"
                >
                  <v-select
                    :items="studios"
                    v-model="studio_id"
                    item-value="id"
                    item-text="studio_name"
                    dense
                    id="selectionsTattooer"
                    outlined
                    hide-details
                    :label="$tc('studio')"
                    data-vv-validate-on="blur"
                    style="display: inline-block"
                  ></v-select
                ></v-col>
                <v-col
                  class="pt-lg-0 pl-0 pl-lg-3 pt-3 pt-sm-0 ml-sm-3"
                  v-if="
                    tab == true &&
                      btn == 'week' &&
                      $store.getters['auth/isStudio']
                  "
                  cols="6"
                  md="4"
                  :style="
                    $vuetify.breakpoint.smOnly
                      ? 'margin-left: -20px; max-width: 120px'
                      : 'max-width: 180px'
                  "
                >
                  <v-select
                    :items="tattooers"
                    v-model="filtersWeek.tattooer"
                    single-line
                    item-value="id"
                    dense
                    id="selectionsTattooer"
                    outlined
                    hide-details
                    :label="$tc('artist')"
                    clearable
                    data-vv-validate-on="blur"
                    @input="filterTable('tattooer')"
                    style="display: inline-block"
                    ><template slot="selection" slot-scope="data">
                      {{ data.item.user.first_name }}
                      {{ data.item.user.last_name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.user.first_name }}
                      {{ data.item.user.last_name }}
                    </template></v-select
                  ></v-col
                ><v-col
                  class="pt-lg-0 ml-lg-0 pl-lg-3 pt-3 pt-sm-0"
                  v-if="
                    tab == true &&
                      btn == 'week' &&
                      $store.getters['auth/isStudio']
                  "
                  cols="6"
                  md="4"
                  :style="
                    $vuetify.breakpoint.smOnly
                      ? 'margin-left: -20px; max-width: 120px'
                      : 'margin-left: -20px; max-width: 200px'
                  "
                >
                  <v-select
                    :items="boxes"
                    v-model="filtersWeek.box"
                    single-line
                    item-value="id"
                    dense
                    clearable
                    :label="$t('boxes.boxs')"
                    outlined
                    hide-details
                    @input="filterTable('box')"
                    v-validate="'required'"
                    style="display: inline-block"
                    ><template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.name }}
                    </template></v-select
                  ></v-col
                ><v-col
                  v-if="
                    tab == true &&
                      btn == 'week' &&
                      !$vuetify.breakpoint.xsOnly &&
                      $store.getters['auth/isStudio']
                  "
                  class="pt-0"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-1 mt-1"
                        value="list"
                        fab
                        outlined
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        @click="tab_week = true"
                        :style="
                          tab_week
                            ? 'height: 31px; width: 31px; border: 3px solid var(--v-primary-base); margin-top: 5px'
                            : 'height: 30px; width: 31px; margin-top: 5px'
                        "
                      >
                        <v-icon size="15">$calendar</v-icon>
                      </v-btn></template
                    >
                    <span>{{ $t("calendar") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        outlined
                        elevation="0"
                        v-bind="attrs"
                        :disabled="filtersWeek.box || filtersWeek.tattooer"
                        v-on="on"
                        @click="tab_week = false"
                        :style="
                          !tab_week
                            ? 'height: 31px; width: 31px; border: 3px solid var(--v-primary-base); margin-top: 5px'
                            : 'height: 30px; width: 31px; margin-top: 5px'
                        "
                      >
                        <v-icon size="15">mdi-percent</v-icon>
                      </v-btn></template
                    >
                    <span>{{ $t("boxes.percentage") }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
        <!-- Buttons lista , tabla formato escritorio-->
        <v-row
          v-if="!$vuetify.breakpoint.xsOnly"
          justify="center"
          style="max-width: 225px"
          ><v-col cols="4" sm="2" md="2" class="mr-2" style="padding-top: 5px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-1"
                  value="list"
                  fab
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  :outlined="tab == false"
                  :disabled="
                    totalBoxes === 0 && $store.getters['auth/isStudio']
                  "
                  @click="tab = true"
                  style="height: 40px; width: 40px"
                >
                  <v-icon size="20">$square</v-icon>
                </v-btn></template
              >
              <span>{{ $t("table") }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4" sm="2" style="padding-top: 5px; margin-right: 37px'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  :outlined="tab == true"
                  @click="
                    tab = false;
                    fetchStatusList();
                    boxesHoursDay();
                  "
                  style="height: 40px; width: 40px"
                >
                  <v-icon size="20">$list</v-icon>
                </v-btn></template
              >
              <span>{{ $t("boxes.list") }}</span>
            </v-tooltip>
          </v-col></v-row
        >
      </v-row>
      <!--Fin buttons lista , tabla formato escritorio-->
      <!--buttons lista , tabla formato movil-->
      <v-row
        v-if="
          (boxes.length === 0 || noBox) &&
            $store.getters['auth/isTattooer'] &&
            load
        "
        justify="center"
      >
        <v-card>
          <v-card-title>
            <v-row class="ma-2">
              <v-col>
                <v-row justify="center">
                  <v-col style="text-align: center">
                    {{ $t("no_boxes") }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-row>
      <template v-else-if="load">
        <v-data-table
          :footer-props="{
            'items-per-page-text': $vuetify.breakpoint.smAndDown
              ? ''
              : $t('items_per_page'),
          }"
          :headers="headers"
          :items="items"
          :search.sync="filters.search"
          :options.sync="options"
          :loading="loading"
          :server-items-length="totalBoxes"
          v-if="tab == false && $store.getters['auth/isStudio']"
          :style="
            $vuetify.breakpoint.xsOnly
              ? 'margin-top: 60px;padding:1px'
              : 'padding:1px'
          "
          id="dataSimple"
          :items-per-page="15"
        >
          <!-- :sort-by.sync="sortBy"
          :server-items-length="totalCustomers"-->
          <template v-slot:item.income="{ item }">
            {{ $n(income(item), "currency") }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-row
                  style="
                    position: relative !important;
                    display: flex;
                    justify-content: center;
                    min-width: 100px;
                  "
                >
                  <v-icon v-on="on" v-bind="attrs" large
                    >mdi-dots-horizontal</v-icon
                  >

                  <!--<v-btn
                    style="margin-top: -2px; position: absolute; right: 20px"
                    v-if="item.order_by == 1"
                    icon
                    @click="moveDown(item)"
                    ><v-icon small>mdi-arrow-down-thick</v-icon></v-btn
                  >

                  <v-btn
                    style="margin-top: -2px; position: absolute; right: 20px"
                    v-else-if="item.order_by == totalBoxes"
                    icon
                    @click="moveUp(item)"
                    ><v-icon small>mdi-arrow-up-thick</v-icon></v-btn
                  >-->

                  <div
                    :style="
                      $vuetify.breakpoint.xsOnly
                        ? 'position: absolute; right: 9px'
                        : 'position: absolute; right: 32px'
                    "
                    icon
                  >
                    <v-row style="margin-top: -2px; height: 20px">
                      <v-btn
                        v-show="item.order_by > 1"
                        style="height: 20px"
                        icon
                        @click="moveUp(item)"
                        ><v-icon small>mdi-arrow-up-thick</v-icon></v-btn
                      >
                    </v-row>
                    <v-row style="height: 20px">
                      <v-btn
                        v-show="item.order_by < totalBoxes"
                        style="height: 20px"
                        icon
                        @click="moveDown(item)"
                        ><v-icon small>mdi-arrow-down-thick</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-row>
              </template>
              <v-list>
                <v-list-item class="cusList" @click="reserva(item.id)">
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -6px !important"
                      >$appointments</v-icon
                    ></v-list-item-icon
                  ><v-list-item-title>
                    <span>{{ $t("boxes.reservar") }}</span></v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  class="cusList"
                  @click="
                    $router.push({
                      name: 'boxView',
                      params: { id: item.id },
                    })
                  "
                >
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -5px !important"
                      >$eye</v-icon
                    ></v-list-item-icon
                  ><v-list-item-title>
                    <span>{{
                      $t("view", { name: $tc("box") })
                    }}</span></v-list-item-title
                  >
                </v-list-item>
                <v-list-item class="cusList" @click="edit(item)">
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -5px !important"
                      >$editprofile</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-title>
                    <span>{{
                      $t("edit", { name: $tc("box") })
                    }}</span></v-list-item-title
                  >
                </v-list-item>
                <v-list-item class="cusList" @click="delBox(item)">
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -6px !important"
                      >$delete</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-title>
                    <span>{{
                      $t("delete", { name: $tc("box") })
                    }}</span></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:item.price="{ item }">
            <span v-if="item.price != null">{{
              $n(item.price, "currency")
            }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.token_payment="{ item }">
            <span v-if="item.token_payment != null">{{
              $n(item.token_payment, "currency")
            }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.user="{ item }">
            <span v-if="item.user != null"
              >{{ item.user.first_name }} {{ item.user.last_name }}</span
            ><span v-else>-</span>
          </template>
          <template v-slot:item.status="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  @click="
                    tab = true;
                    btn = 'day';
                  "
                  v-if="item.status != null"
                  v-on="on"
                  v-bind="attrs"
                  :style="
                    boxesHoursDay(item.id).percentage >= 0.5
                      ? boxesHoursDay(item.id).percentage >= 0.75
                        ? boxesHoursDay(item.id).percentage == 1
                          ? 'color:red; cursor:pointer'
                          : 'color:orange; cursor:pointer'
                        : 'color:yellow; cursor:pointer'
                      : 'cursor:pointer'
                  "
                  >{{ $n(boxesHoursDay(item.id).percentage, "percent") }} /
                  {{ boxesHoursDay(item.id).hour }}h
                </span>
              </template>

              <span>
                {{ $n(boxesHoursDay(item.id).percentage, "percent") }}
                {{ $t("ocupation") }} / {{ boxesHoursDay(item.id).hour }}
                {{ $t("hours_available") }}
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-data-table
          :footer-props="{
            'items-per-page-text': $vuetify.breakpoint.smAndDown
              ? ''
              : $t('items_per_page'),
          }"
          :headers="headersTattooer"
          :items="itemsTattooer"
          :options.sync="optionsTattooer"
          :server-items-length="totalReservations"
          v-if="tab == false && $store.getters['auth/isTattooer']"
          :style="
            $vuetify.breakpoint.xsOnly
              ? 'margin-top: 60px;padding:1px'
              : 'padding:1px'
          "
        >
          <template v-slot:item.data="{ item }">
            {{ $d(new Date(item.start_date)) }} {{ item.start_time }} -
            {{ item.end_time }}
          </template>
          <template v-slot:item.concept="{ item }">
            {{
              item.appointment_id
                ? $t("boxes.Appointment")
                : $t("boxes.reserva_box")
            }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-row
                  style="
                    position: relative !important;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <v-icon v-on="on" v-bind="attrs" large
                    >mdi-dots-horizontal</v-icon
                  >
                </v-row>
              </template>
              <v-list>
                <v-list-item class="cusList" @click="delReservation(item)">
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -6px !important"
                      >$delete</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-title>
                    <span>{{
                      $t("delete", { name: "" })
                    }}</span></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <v-dialog max-width="300" v-model="newBox1">
          <v-row class="ma-0">
            <v-card class="popupCard"
              ><v-card-title
                style="text-decoration: underline; text-transform: uppercase"
              >
                {{ $t("boxes.new_box") }}
              </v-card-title>
              <div class="close">
                <v-btn icon @click="newBox1 = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <base-material-card class="base">
                <form
                  action
                  data-vv-scope="box-form"
                  @submit.prevent="validateForm('box-form')"
                >
                  <v-row class="pt-0 pb-0" style="width: 73%; margin: 0 auto">
                    <v-col cols="12" class="pt-0 pb-2">
                      <label style="font-size: 12px">{{
                        $t("boxes.name")
                      }}</label>
                      <v-text-field
                        outlined
                        dense
                        v-model="box.name"
                        v-validate="'required|min:1|max:100'"
                        :error-messages="errors.collect('box-form.name')"
                        color="secondary"
                        hide-details
                        data-vv-validate-on="blur"
                        data-vv-name="name"
                      />
                    </v-col>
                    <v-col
                      v-if="
                        this.$store.getters['auth/getSetting']('box_price') == 1
                      "
                      class="pt-0 pb-2"
                      cols="12"
                    >
                      <label style="font-size: 12px">{{
                        $t("boxes.price")
                      }}</label>

                      <number-input-with-separators
                        outlined
                        dense
                        hide-details
                        :value="box"
                        valueKey="price"
                        rules="required|is_not:0€"
                        ref="money_1"
                      ></number-input-with-separators>
                    </v-col>
                    <v-col
                      v-if="
                        this.$store.getters['auth/getSetting']('payment_box') ==
                          1
                      "
                      class="pt-0 pb-5"
                      cols="12"
                    >
                      <label style="font-size: 12px">{{
                        $t("boxes.token_payment")
                      }}</label>

                      <number-input-with-separators
                        outlined
                        dense
                        hide-details
                        :value="box"
                        valueKey="token_payment"
                        rules="required|is_not:0€"
                        ref="money_2"
                      ></number-input-with-separators>
                    </v-col>
                  </v-row>

                  <v-row
                    class="pt-3 pl-2 pb-3"
                    style="width: 100%"
                    justify="center"
                  >
                    <v-btn
                      class="mr-3"
                      rounded
                      outlined
                      style="height: 30px; width: 100px"
                      color="primary"
                      @click="(newBox1 = false), (item2 = false)"
                      >{{ $t("cancel") }}</v-btn
                    >
                    <v-btn
                      class="mr-0"
                      rounded
                      elevation="0"
                      style="height: 30px; width: 100px !important"
                      color="primary"
                      type="submit"
                      ><v-icon style="margin-right: 5px" size="14px"
                        >$save</v-icon
                      >{{ $t("save", { name: $t("") }) }}</v-btn
                    >
                  </v-row>
                </form>
              </base-material-card>
            </v-card>
          </v-row>
        </v-dialog>
        <!-- Vista un dia-->
        <v-simple-table
          class="pb-5 mt-sm-5"
          id="tableBoxDay"
          :style="
            $vuetify.breakpoint.xsOnly
              ? 'margin-top: 100px;min-width: 100% !important'
              : 'min-width: 100% !important'
          "
          v-if="tab == true && btn == 'day'"
        >
          <tr>
            <th colspan="1"></th>
            <th colspan="1"></th>
            <th
              :key="key.id"
              v-for="key in table"
              style="
                text-transform: uppercase;
                text-decoration: underline;
                position: relative;
              "
              class="pb-0"
            >
              <v-btn
                style="margin-top: -7px; position: absolute; left: 0"
                v-if="key.order_by > 1 && $store.getters['auth/isStudio']"
                icon
                @click="moveUp(key)"
                ><v-icon size="25">mdi-arrow-left-thick</v-icon></v-btn
              >
              <span
                @click="
                  $store.getters['auth/isStudio']
                    ? $router.push({
                        name: 'boxView',
                        params: { id: key.id },
                      })
                    : null
                "
                :style="
                  $store.getters['auth/isStudio']
                    ? 'display: inline; cursor: pointer'
                    : 'display: inline; cursor: default !important'
                "
                >{{ key.name }}</span
              >
              <v-btn
                style="margin-top: -7px; position: absolute; right: 0"
                v-if="
                  key.order_by < table.length && $store.getters['auth/isStudio']
                "
                icon
                @click="moveDown(key)"
                ><v-icon size="25">mdi-arrow-right-thick</v-icon></v-btn
              >
            </th>
          </tr>
          <tr>
            <th colspan="1"></th>
            <th colspan="1"></th>
            <th :key="key.id" v-for="key in table">
              <div
                class="mb-1"
                @click="
                  $store.getters['auth/isStudio']
                    ? $router.push({
                        name: 'tattooerView',
                        params: { id: key.tattooer.user.id },
                      })
                    : ''
                "
                v-if="key.tattooer_id != null"
              >
                <div
                  v-if="
                    $store.getters['auth/isStudio'] ||
                      ($store.getters['auth/isTattooer'] &&
                        key.tattooer.user.id ==
                          $store.state.auth.user.tattooer.id)
                  "
                  class="rounded-pill pt-0"
                  :style="
                    $store.getters['auth/isStudio']
                      ? `
                  border: 2px solid var(--v-primary-base);
                  height: 30px;
                  line-height: 25px; 
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  background-color:${key.tattooer.color};
                  cursor: pointer;
                  font-weight: normal;
                  color: ${
                    $store.getters['app/isLight'](key.tattooer.color)
                      ? 'white'
                      : 'black'
                  }`
                      : `border: 2px solid var(--v-primary-base);
                  height: 30px;
                  line-height: 25px; 
                  background-color:${key.tattooer.color};
                  font-weight: normal;
                  cursor: default;
                  color: ${
                    $store.getters['app/isLight'](key.tattooer.color)
                      ? 'white'
                      : 'black'
                  }`
                  "
                >
                  {{ key.tattooer.user.first_name }}
                  {{ key.tattooer.user.last_name }}
                </div>
              </div>
              <p v-else></p>
            </th>
          </tr>
          <tr :key="h" v-for="(h, i) in hoursRow">
            <td
              v-if="hoursRow.length > i + 1"
              :style="
                h.indexOf(':30') !== -1
                  ? 'visibility: hidden; border-top:0px'
                  : ''
              "
            >
              <span>{{ h }}</span>
            </td>

            <td v-else style="">
              <span
                >{{ +hoursRow[i - 1].split(":")[0] + 1 }}:{{
                  hoursRow[i - 1].split(":")[1]
                }}</span
              >
            </td>
            <td
              colspan="1"
              width="1px"
              :style="h.indexOf(':30') !== -1 ? ' border-top:0px' : ''"
            ></td>
            <template v-for="box in table">
              <td
                :key="box.id"
                v-if="tattooerHours(h, box).reserva"
                :rowspan="tattooerHours(h, box).colspan"
                style="cursor: pointer; border: 2px solid var(--v-primary-base)"
                v-bind:style="[
                  {
                    'background-color':
                      $store.getters['auth/isTattooer'] &&
                      tattooerHours(h, box).reserva.tattooer.id !==
                        $store.state.auth.user.tattooer.id
                        ? 'var(--v-primary-base)'
                        : tattooerHours(h, box).color,
                  },
                  {
                    'border-color':
                      $store.getters['auth/isTattooer'] &&
                      tattooerHours(h, box).reserva.tattooer.id !==
                        $store.state.auth.user.tattooer.id
                        ? 'var(--v-primary-base)'
                        : 'var(--v-primary-base)',
                  },
                ]"
                @click="
                  $store.getters['auth/isTattooer'] &&
                  tattooerHours(h, box).reserva.tattooer.id !==
                    $store.state.auth.user.tattooer.id
                    ? null
                    : showEvent({
                        event: tattooerHours(h, box).reserva,
                        box: box,
                        nativeEvent: $event,
                      })
                "
              >
                <template
                  v-if="
                    !(
                      $store.getters['auth/isTattooer'] &&
                      tattooerHours(h, box).reserva.tattooer.id !==
                        $store.state.auth.user.tattooer.id
                    )
                  "
                >
                  <v-tooltip bottom v-if="tattooerHours(h, box).colspan === 1">
                    <template v-slot:activator="{ on }">
                      <span class="tattooer_name single-line px-1" v-on="on">
                        {{
                          !tab_day
                            ? tattooerHours(h, box).reserva.tattooer.user
                                .first_name
                            : tattooerHours(h, box).reserva.appointments
                            ? tattooerHours(h, box).reserva.appointments
                                .customer.user.first_name
                            : tattooerHours(h, box).reserva.tattooer.user
                                .first_name
                        }}
                        {{
                          !tab_day
                            ? tattooerHours(h, box).reserva.tattooer.user
                                .last_name
                            : tattooerHours(h, box).reserva.appointments
                            ? tattooerHours(h, box).reserva.appointments
                                .customer.user.last_name
                            : tattooerHours(h, box).reserva.tattooer.user
                                .last_name
                        }}
                      </span>
                    </template>

                    {{
                      !tab_day
                        ? tattooerHours(h, box).reserva.tattooer.user.first_name
                        : tattooerHours(h, box).reserva.appointments
                        ? tattooerHours(h, box).reserva.appointments.customer
                            .user.first_name
                        : tattooerHours(h, box).reserva.tattooer.user.first_name
                    }}
                    {{
                      !tab_day
                        ? tattooerHours(h, box).reserva.tattooer.user.last_name
                        : tattooerHours(h, box).reserva.appointments
                        ? tattooerHours(h, box).reserva.appointments.customer
                            .user.last_name
                        : tattooerHours(h, box).reserva.tattooer.user.last_name
                    }}
                  </v-tooltip>
                  <span v-else class="tattooer_name">
                    {{
                      !tab_day
                        ? tattooerHours(h, box).reserva.tattooer.user.first_name
                        : tattooerHours(h, box).reserva.appointments
                        ? tattooerHours(h, box).reserva.appointments.customer
                            .user.first_name
                        : tattooerHours(h, box).reserva.tattooer.user.first_name
                    }}
                    {{
                      !tab_day
                        ? tattooerHours(h, box).reserva.tattooer.user.last_name
                        : tattooerHours(h, box).reserva.appointments
                        ? tattooerHours(h, box).reserva.appointments.customer
                            .user.last_name
                        : tattooerHours(h, box).reserva.tattooer.user.last_name
                    }}
                  </span>
                </template>
                <template v-else> Ocupado </template>
              </td>
              <td
                :key="box.id"
                v-else-if="rowspan(h, box)"
                @click="reserva(h, box)"
                :style="h.indexOf(':30') !== -1 ? ' border-top:0px' : ''"
                v-bind:class="[{ gris2: checkTime(h, date) }]"
              ></td>
            </template>
          </tr>
        </v-simple-table>

        <v-row
          v-if="tab == true && btn == 'week' && tab_week == false"
          class="px-3"
        >
          <!-- ! Vista SEMANAL PORCENTAJES-->
          <v-simple-table
            class="pb-5 mt-5"
            id="tableBox"
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'margin-top: 110px !important;min-width: 100% !important'
                : 'min-width: 100% !important'
            "
            v-if="filtersWeek.tattooer == null && filtersWeek.box == null"
          >
            <tr>
              <th colspan="1"></th>
              <th colspan="1"></th>
              <th
                :key="day"
                v-for="day in daysWeek"
                style="cursor: auto; text-transform: capitalize"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                  "
                >
                  <div
                    class="pa-0 rounded-pill"
                    style="
                      background-color: var(--v-primary-base);
                      width: 90% !important;
                      height: 30px;
                      color: var(--v-gris3-base);
                      font-size: 12px;
                      font-weight: 200;
                      line-height: 30px;
                      cursor: default;
                    "
                  >
                    {{ $d(new Date(day), "weekDay") }}
                  </div>
                  <div
                    v-if="day != today"
                    class="my-2 mt-3 rounded-circle"
                    style="
                      border: 1px solid var(--v-primary-base);
                      height: 45px;
                      width: 45px;
                      line-height: 43px;
                      color: var(--v-primary-base);
                      font-weight: 400;
                      font-size: 25px;
                      cursor: pointer;
                    "
                    @click="
                      btn = 'day';
                      date = day;
                      fetchTable();
                    "
                  >
                    {{ $d(new Date(day), "day") }}
                  </div>
                  <div
                    v-else
                    class="my-2 mt-3 rounded-circle"
                    style="
                      background-color: var(--v-primary-base);
                      height: 45px;
                      width: 45px;
                      line-height: 45px;
                      color: var(--v-gris3-base);

                      font-weight: 400;
                      font-size: 25px;
                      cursor: pointer;
                    "
                    @click="
                      btn = 'day';
                      date = day;
                      fetchTable();
                    "
                  >
                    {{ $d(new Date(day), "day") }}
                  </div>
                </div>
              </th>
            </tr>

            <tr
              :key="h"
              v-for="(h, i) in hoursRow.filter((h) => h.indexOf(':30') === -1)"
            >
              <td>
                <span
                  v-if="
                    hoursRow.filter((h) => h.indexOf(':30') === -1).length ==
                      i + 1
                  "
                  style="margin-top: 20px; position: absolute; left: 54px"
                  >{{ +h.split(":")[0] + 1 }}:{{ h.split(":")[1] }}</span
                >
                <span>{{ h }}</span>
              </td>

              <td colspan="1" width="1px"></td>

              <template v-for="day in daysWeek">
                <td
                  :key="day"
                  v-if="boxesHours(h, day).reserva"
                  :style="
                    boxesHours(h, day).reserva >= 0.5
                      ? boxesHours(h, day).reserva >= 0.75
                        ? boxesHours(h, day).reserva == 1
                          ? 'color:red; position:relative'
                          : 'color:orange; position:relative'
                        : 'color:yellow; position:relative'
                      : 'position:relative'
                  "
                  @click="
                    btn = 'day';
                    date = day;
                    fetchTable();
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="pa-0 ma-0" v-on="on" v-bind="attrs">
                        {{ $n(boxesHours(h, day).reserva, "percent") }} /
                        {{ boxesHours(h, day).hours }}h
                      </div>
                    </template>
                    <span
                      >{{ $n(boxesHours(h, day).reserva, "percent") }}
                      {{ $t("ocupation") }} / {{ boxesHours(h, day).hours }}
                      {{ $t("hours_available") }}</span
                    >
                  </v-tooltip>
                </td>

                <td v-else :key="day"></td>
              </template>
            </tr>
          </v-simple-table>

          <!-- SEMANAL-->
        </v-row>
        <v-row
          v-if="tab == true && btn == 'week' && tab_week == true"
          class="px-3"
        >
          <!-- ! Vista SEMANAL GLOBAL-->

          <v-simple-table
            class="pb-5 mt-5"
            id="tableTattooerWeek"
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'margin-top: 100px !important;min-width: 100% !important'
                : 'min-width: 100% !important'
            "
            v-if="
              this.filtersWeek.tattooer == null && this.filtersWeek.box == null
            "
          >
            <tr>
              <th colspan="1"></th>
              <th colspan="1"></th>
              <th
                :key="day"
                v-for="day in daysWeek"
                style="cursor: auto; text-transform: capitalize"
                :colspan="table.length"
              >
                <div
                  style="
                    cursor: default;

                    display: flex;
                    align-items: center;
                    flex-direction: column;
                  "
                  v-bind:style="[
                    {
                      'background-color':
                        $store.getters['auth/isTattooer'] &&
                        tattooerHoursWeek(h, box, day).reserva &&
                        tattooerHoursWeek(h, box, day).reserva.tattooer.id !==
                          $store.state.auth.user.tattooer.id
                          ? 'var(--v-primary-base)'
                          : tattooerHoursWeek(h, box, day).color,
                    },
                  ]"
                  @click="
                    filterTattooer(
                      tattooerHoursWeek(h, box, day).reserva.tattooer.id
                    )
                  "
                >
                  <div
                    class="pa-0 rounded-pill"
                    style="
                      background-color: var(--v-primary-base);
                      width: 90% !important;
                      height: 30px;
                      color: var(--v-gris3-base);
                      font-size: 12px;
                      font-weight: 200;
                      line-height: 30px;
                      cursor: default;
                    "
                  >
                    {{ $d(new Date(day), "weekDay") }}
                  </div>
                  <div
                    v-if="day != today"
                    class="my-2 mt-3 rounded-circle"
                    style="
                      border: 1px solid var(--v-primary-base);
                      height: 45px;
                      width: 45px;
                      line-height: 43px;
                      color: var(--v-primary-base);
                      font-weight: 400;
                      font-size: 25px;
                      cursor: pointer;
                    "
                    @click="
                      btn = 'day';
                      date = day;
                      fetchTable();
                    "
                  >
                    {{ $d(new Date(day), "day") }}
                  </div>
                  <div
                    v-else
                    class="my-2 mt-3 rounded-circle"
                    style="
                      background-color: var(--v-primary-base);
                      height: 45px;
                      width: 45px;
                      line-height: 45px;
                      color: var(--v-gris3-base);

                      font-weight: 400;
                      font-size: 25px;
                      cursor: pointer;
                    "
                    @click="
                      btn = 'day';
                      date = day;
                      fetchTable();
                    "
                  >
                    {{ $d(new Date(day), "day") }}
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th colspan="1"></th>
              <th colspan="1"></th>

              <template v-for="(day, i) in daysWeek">
                <template v-for="(box, j) in table">
                  <th
                    :key="`${i}-${j}`"
                    :style="
                      table.length - 1 == j
                        ? 'border-right: 1px solid var(--v-primary-base)'
                        : ''
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="display: flex; justify-content: center"
                          v-on="on"
                          v-bind="attrs"
                        >
                          <span style="letter-spacing: 0px; font-size: 8px"
                            >{{ box.order_by }}
                          </span>
                        </div>
                      </template>
                      <span>{{ box.name }}</span>
                    </v-tooltip>
                  </th>
                </template>
              </template>
            </tr>
            <tr :key="h" v-for="(h, i) in hoursRow">
              <td
                v-if="hoursRow.length > i + 1"
                :style="
                  h.indexOf(':30') !== -1
                    ? 'visibility: hidden; border-top:0px'
                    : ''
                "
              >
                <span>{{ h }}</span>
              </td>

              <td v-else style="">
                <span
                  >{{ +hoursRow[i - 1].split(":")[0] + 1 }}:{{
                    hoursRow[i - 1].split(":")[1]
                  }}</span
                >
              </td>
              <td
                colspan="1"
                width="1px"
                :style="h.indexOf(':30') !== -1 ? ' border-top:0px' : ''"
              ></td>

              <template v-for="(day, i) in daysWeek">
                <template v-for="(box, j) in table">
                  <td
                    :rowspan="tattooerHoursWeek(h, box, day).colspan"
                    :key="`${i}-${j}`"
                    v-if="tattooerHoursWeek(h, box, day).reserva"
                    style="
                      cursor: default;
                      border: 2px solid var(--v-primary-base);
                    "
                    v-bind:style="[
                      {
                        'background-color':
                          $store.getters['auth/isTattooer'] &&
                          tattooerHoursWeek(h, box, day).reserva.tattooer.id !==
                            $store.state.auth.user.tattooer.id
                            ? 'var(--v-primary-base)'
                            : tattooerHoursWeek(h, box, day).color,
                      },
                    ]"
                    @click="
                      filterTattooer(
                        tattooerHoursWeek(h, box, day).reserva.tattooer.id
                      )
                    "
                  >
                    <template
                      v-if="
                        !(
                          $store.getters['auth/isTattooer'] &&
                          tattooerHoursWeek(h, box, day).reserva.tattooer.id !==
                            $store.state.auth.user.tattooer.id
                        )
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="pa-0 ma-0"
                            v-on="on"
                            v-bind="attrs"
                            style="height: 100%; width: 100%; cursor: pointer"
                          ></div>
                        </template>
                        <span
                          >{{
                            tattooerHoursWeek(h, box, day).reserva.tattooer.user
                              .first_name
                          }}
                          {{
                            tattooerHoursWeek(h, box, day).reserva.tattooer.user
                              .last_name
                          }}
                        </span>
                      </v-tooltip>
                    </template>
                    <template v-else
                      ><v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="pa-0 ma-0"
                            v-on="on"
                            v-bind="attrs"
                            style="height: 100%; width: 100%; cursor: default"
                          ></div>
                        </template>
                        <span> Ocupado </span>
                      </v-tooltip>
                    </template>
                  </td>
                  <td
                    :key="`${i}-${j}`"
                    v-else-if="rowspanTattooerWeek(h, box, day)"
                    :style="h.indexOf(':30') !== -1 ? ' border-top:0px' : ''"
                    v-bind:class="[{ gris2: checkTime(h, day) }]"
                  ></td>
                </template>
              </template>
            </tr>
          </v-simple-table>

          <!-- Fin View-->
          <!-- ! Vista SEMANAL TATTOOER-->

          <v-simple-table
            class="pb-5 mt-5"
            id="tableBoxWeek"
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'margin-top: 110px !important;min-width: 100% !important'
                : 'min-width: 100% !important'
            "
            v-else-if="filtersWeek.tattooer && this.filtersWeek.box == null"
          >
            <tr>
              <th colspan="1"></th>
              <th colspan="1"></th>
              <th
                :key="day"
                v-for="day in daysWeek"
                style="text-transform: capitalize"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                  "
                >
                  <div
                    class="pa-0 rounded-pill"
                    style="
                      background-color: var(--v-primary-base);
                      width: 90% !important;
                      height: 30px;
                      color: var(--v-gris3-base);
                      font-size: 13px;
                      font-weight: 200;
                      line-height: 30px;
                      cursor: default;
                    "
                  >
                    {{ $d(new Date(day), "weekDay") }}
                  </div>
                  <div
                    v-if="day != today"
                    class="my-2 mt-3 rounded-circle"
                    style="
                      border: 1px solid var(--v-primary-base);
                      height: 45px;
                      width: 45px;
                      line-height: 43px;
                      color: var(--v-primary-base);

                      font-weight: 400;
                      font-size: 25px;
                      cursor: pointer;
                    "
                    @click="
                      btn = 'day';
                      date = day;
                      fetchTable();
                    "
                  >
                    {{ $d(new Date(day), "day") }}
                  </div>
                  <div
                    v-else
                    class="my-2 mt-3 rounded-circle"
                    style="
                      background-color: var(--v-primary-base);
                      height: 45px;
                      width: 45px;
                      line-height: 45px;
                      color: var(--v-gris3-base);

                      font-weight: 400;
                      font-size: 25px;
                      cursor: pointer;
                    "
                    @click="
                      btn = 'day';
                      date = day;
                      fetchTable();
                    "
                  >
                    {{ $d(new Date(day), "day") }}
                  </div>
                </div>
              </th>
            </tr>

            <tr :key="h" v-for="(h, i) in hoursRow">
              <td
                v-if="hoursRow.length > i + 1"
                :style="
                  h.indexOf(':30') !== -1
                    ? 'visibility: hidden; border-top:0px'
                    : ''
                "
              >
                <span>{{ h }}</span>
              </td>

              <td v-else style="">
                <span
                  >{{ +hoursRow[i - 1].split(":")[0] + 1 }}:{{
                    hoursRow[i - 1].split(":")[1]
                  }}</span
                >
              </td>

              <td
                colspan="1"
                width="1px"
                :style="h.indexOf(':30') !== -1 ? ' border-top:0px' : ''"
              ></td>
              <template v-for="day in daysWeek">
                <td
                  :key="day"
                  v-if="tattooersWeekHours(h, day).reserva"
                  :rowspan="tattooersWeekHours(h, day).colspan"
                  :style="
                    `cursor: default;border: 2px solid var(--v-primary-base); background-color:${
                      tattooersWeekHours(h, day).color
                    }`
                  "
                  @click="
                    showEvent({
                      event: tattooersWeekHours(h, day).reserva,
                      box: box,
                      nativeEvent: $event,
                    })
                  "
                >
                  {{ tattooersWeekHours(h, day).reserva.boxes.name }}
                </td>
                <td
                  :key="day"
                  v-else-if="rowspanTattooer(h, day)"
                  :style="h.indexOf(':30') !== -1 ? ' border-top:0px' : ''"
                  v-bind:class="[{ gris2: checkTime(h, day) }]"
                ></td>
              </template>
            </tr>
          </v-simple-table>

          <!-- ! Vista SEMANAL BOX-->

          <v-simple-table
            class="pb-5 mt-5"
            id="tableBoxWeek"
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'margin-top: 110px !important;min-width: 100% !important'
                : 'min-width: 100% !important'
            "
            v-else
          >
            <tr>
              <th colspan="1"></th>
              <th colspan="1"></th>
              <th
                :key="day"
                v-for="day in daysWeek"
                style="text-transform: capitalize"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                  "
                >
                  <div
                    class="pa-0 rounded-pill"
                    style="
                      background-color: var(--v-primary-base);
                      width: 90% !important;
                      height: 30px;
                      color: var(--v-gris3-base);
                      font-size: 13px;
                      font-weight: 200;
                      line-height: 30px;
                      cursor: default;
                    "
                  >
                    {{ $d(new Date(day), "weekDay") }}
                  </div>
                  <div
                    v-if="day != today"
                    class="my-2 mt-3 rounded-circle"
                    style="
                      border: 1px solid var(--v-primary-base);
                      height: 45px;
                      width: 45px;
                      line-height: 43px;
                      color: var(--v-primary-base);

                      font-weight: 400;
                      font-size: 25px;
                      cursor: pointer;
                    "
                    @click="
                      btn = 'day';
                      date = day;
                      fetchTable();
                    "
                  >
                    {{ $d(new Date(day), "day") }}
                  </div>
                  <div
                    v-else
                    class="my-2 mt-3 rounded-circle"
                    style="
                      background-color: var(--v-primary-base);
                      height: 45px;
                      width: 45px;
                      line-height: 45px;
                      color: var(--v-gris3-base);

                      font-weight: 400;
                      font-size: 25px;
                      cursor: pointer;
                    "
                    @click="
                      btn = 'day';
                      date = day;
                      fetchTable();
                    "
                  >
                    {{ $d(new Date(day), "day") }}
                  </div>
                </div>
              </th>
            </tr>

            <tr :key="h" v-for="(h, i) in hoursRow">
              <td
                v-if="hoursRow.length > i + 1"
                :style="
                  h.indexOf(':30') !== -1
                    ? 'visibility: hidden; border-top:0px'
                    : ''
                "
              >
                <span>{{ h }}</span>
              </td>

              <td v-else style="">
                <span
                  >{{ +hoursRow[i - 1].split(":")[0] + 1 }}:{{
                    hoursRow[i - 1].split(":")[1]
                  }}</span
                >
              </td>
              <td
                colspan="1"
                width="1px"
                :style="h.indexOf(':30') !== -1 ? ' border-top:0px' : ''"
              ></td>
              <template v-for="day in daysWeek">
                <td
                  :key="day"
                  v-if="boxesWeekHours(h, day).reserva"
                  :rowspan="boxesWeekHours(h, day).colspan"
                  :style="
                    ` cursor: default; border: 2px solid var(--v-primary-base); background-color:${
                      boxesWeekHours(h, day).color
                    }`
                  "
                  @click="
                    showEvent({
                      event: boxesWeekHours(h, day).reserva,
                      box: box,
                      nativeEvent: $event,
                    })
                  "
                >
                  <span>
                    {{
                      boxesWeekHours(h, day).reserva.tattooer.user.first_name
                    }}
                    {{ boxesWeekHours(h, day).reserva.tattooer.user.last_name }}
                  </span>
                </td>
                <td
                  :key="day"
                  v-else-if="rowspanBox(h, day)"
                  :style="h.indexOf(':30') !== -1 ? ' border-top:0px' : ''"
                  v-bind:class="[{ gris2: checkTime(h, day) }]"
                ></td>
              </template>
            </tr>
          </v-simple-table>
        </v-row>
        <!-- <BoxFilterCard :res="res" ref="BoxFilter"></BoxFilterCard>-->
        <!-- FIN SEMANAL -->
        <v-dialog max-width="500" v-model="dialog">
          <v-row class="ma-0">
            <v-card class="popupCard"
              ><v-card-title
                style="text-decoration: underline; text-transform: uppercase"
              >
                {{ $t("boxes.reservation") }}
              </v-card-title>
              <v-card-subtitle
                style="
                  text-decoration: underline;
                  text-transform: uppercase;
                  color: var(--v-primary-base);
                  margin-top: 17px;
                  font-size: 18px;
                  display: flex;
                  justify-content: center;
                "
                class="pa-0"
                v-if="resTat == true"
              >
                {{ idBox(res.id).name }}
              </v-card-subtitle>
              <div class="close">
                <v-btn icon @click="dialog = false" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <base-material-card class="base">
                <form
                  action
                  data-vv-scope="reserva-form"
                  @submit.prevent="validateFormReserva('reserva-form')"
                >
                  <v-row
                    ><v-col cols="6" class="pl-6"
                      ><label v-text="$t('boxes.tattooer')" />
                      <v-select
                        v-if="$store.getters['auth/isStudio']"
                        :items="tattooers"
                        v-model="res.tattooer"
                        single-line
                        item-value="id"
                        data-vv-name="tattooer"
                        dense
                        outlined
                        hide-details
                        data-vv-validate-on="blur"
                        @change="
                          fetchAllVacationsTattooer(
                            res.tattooer,
                            $store.state.auth.user.studio.id
                          )
                        "
                        :error-messages="
                          errors.collect('reserva-form.tattooer')
                        "
                        v-validate="'required'"
                        style="display: inline-block; width: 100%"
                        ><template slot="selection" slot-scope="data">
                          {{ data.item.user.first_name }}
                          {{
                            data.item.user.last_name !== null &&
                            data.item.user.last_name !== "null"
                              ? data.item.user.last_name
                              : ""
                          }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.user.first_name }}
                          {{
                            data.item.user.last_name !== null
                              ? data.item.user.last_name
                              : ""
                          }}
                        </template></v-select
                      >
                      <v-text-field
                        v-else
                        disabled
                        :value="
                          $store.state.auth.user.first_name +
                            ' ' +
                            ($store.state.auth.user.last_name
                              ? $store.state.auth.user.last_name
                              : '')
                        "
                        outlined
                        dense
                      ></v-text-field> </v-col
                    ><v-col cols="6" v-if="!resTat" class="pr-6"
                      ><label v-text="$t('box')" />
                      <v-select
                        :items="boxes"
                        v-model="res.id"
                        :disabled="resTat !== false"
                        single-line
                        item-value="id"
                        dense
                        outlined
                        hide-details
                        data-vv-name="boxes"
                        :error-messages="errors.collect('reserva-form.boxes')"
                        data-vv-validate-on="blur"
                        v-validate="'required'"
                        style="display: inline-block; width: 100%"
                        ><template slot="selection" slot-scope="data">
                          {{ data.item.name }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.name }}
                        </template></v-select
                      ></v-col
                    ><v-col cols="6" class="" v-if="resTat == true">
                      <v-switch class="mt-6 ml-5" v-model="res.associate">
                        <template v-slot:label
                          ><div class="mt-0">
                            {{ $t("boxes.associate_tattooer") }}
                          </div>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>

                  <v-row style="width: 100%; margin: 0 auto">
                    <v-col
                      id="time"
                      cols="12"
                      md="4"
                      lg="5"
                      style="margin-top: -20px"
                      class="py-md-3 pb-0 pt-0"
                    >
                      <label v-text="$t('events.date')" />
                      <v-menu
                        ref="menu"
                        v-model="menu2"
                        transition="scale-transition"
                        offset-y
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            hide-details
                            dense
                            v-validate="'required'"
                            data-vv-name="startDate"
                            :error-messages="
                              errors.collect('reserva-form.startDate')
                            "
                            class="filters"
                            readonly
                            v-bind:class="{
                              'error--text': dateError || horarioError,
                            }"
                            v-bind="attrs"
                            v-on="on"
                            :value="
                              res.date
                                ? $d(new Date(res.date), 'date2digits')
                                : ''
                            "
                            ><template v-slot:prepend-inner>
                              <!--Aqui va el v-icon -->
                              <v-icon
                                small
                                class="pt-0 pb-1"
                                style="margin-top: 5px"
                                :color="edit == false ? 'gris1' : 'primary'"
                                >$appointments</v-icon
                              >
                            </template></v-text-field
                          >
                        </template>
                        <v-date-picker
                          first-day-of-week="1"
                          v-model="res.date"
                          no-title
                          scrollable
                          color="primary"
                          :min="date"
                          @input="
                            menu2 = false;
                            fetchTattooers();
                          "
                          :allowed-dates="allowedDates"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col
                      cols="5"
                      sm="3"
                      md="3"
                      lg="3"
                      class="py-md-3 pb-0 pt-5 mt-n5"
                    >
                      <label v-text="$t('events.startTime')" />
                      <v-select
                        :items="hours"
                        v-model="res.start"
                        dense
                        outlined
                        hide-details
                        data-vv-validate-on="blur"
                        v-validate="'required'"
                        v-bind:class="{ 'error--text': timeError }"
                        data-vv-name="startTime"
                        :error-messages="
                          errors.collect('reserva-form.startTime')
                        "
                      ></v-select>
                    </v-col>

                    <p
                      class="pa-1 mt-5 mb-0 pt-3"
                      style="display: inline-block; text-align: center"
                    >
                      -
                    </p>
                    <v-col
                      cols="5"
                      sm="3"
                      md="3"
                      lg="3"
                      class="py-md-3 pb-0 pt-5 mt-n5"
                    >
                      <label v-text="$t('events.endTime')" />

                      <v-select
                        :items="hours"
                        v-model="res.end"
                        hide-details
                        v-bind:class="{ 'error--text': timeError }"
                        dense
                        data-vv-validate-on="blur"
                        :v-validate="res.associate ? '' : 'required'"
                        :error-messages="errors.collect('reserva-form.endTime')"
                        data-vv-name="endTime"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="dateError || horarioError"
                    class="py-0"
                    style="width: 100%; margin: 0 auto"
                  >
                    <v-col
                      class="
                        login_error
                        white--text
                        pa-1
                        text-center
                        rounded-xl
                        my-0
                      "
                      >Está fuera del horario establecido</v-col
                    >
                  </v-row>
                  <v-row
                    style="width: 100%; margin: 0 auto"
                    class="pt-3 mb-3 pr-3"
                    justify="end"
                  >
                    <v-btn
                      outlined
                      @click="dialog = false"
                      class="mr-3"
                      style="height: 30px; width: 100px"
                      elevation="0"
                      >{{ $t("cancel") }}</v-btn
                    >
                    <v-btn
                      type="submit"
                      elevation="0"
                      style="height: 30px; width: 100px !important"
                      ><v-icon small style="margin-right: 5px">$save</v-icon
                      >{{ $t("save", { name: "" }) }}</v-btn
                    >
                  </v-row>
                </form>
              </base-material-card>
            </v-card>
          </v-row>
        </v-dialog>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-y
          id="menu"
          style="max-width: 450px"
        >
          <v-card
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'min-width: 150px; max-width:350px; border: 1px solid ' +
                  selectedEvent.tattooer.color
                : 'min-width: 350px; max-width:450px; border: 1px solid ' +
                  selectedEvent.tattooer.color
            "
            class="ma-0"
            flat
            id="eventView"
            v-if="selectedEvent"
          >
            <v-toolbar dark>
              <v-toolbar-title>
                <v-icon
                  class="mb-1 mr-2"
                  size="14"
                  :color="selectedEvent.tattooer.color"
                  >mdi-circle</v-icon
                >
                <v-tooltip bottom v-if="$vuetify.breakpoint.xs">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ selectedEvent.name }}</span>
                  </template>
                </v-tooltip>
                <span
                  style="cursor: pointer"
                  @click="
                    $store.getters['auth/isStudio']
                      ? $router.push({
                          name: 'tattooerView',
                          params: { id: selectedEvent.tattooer_id },
                        })
                      : null
                  "
                  v-else
                >
                  {{
                    !tab_day
                      ? selectedEvent.tattooer.user.first_name
                      : selectedEvent.appointments
                      ? selectedEvent.appointments.customer.user.first_name
                      : selectedEvent.tattooer.user.first_name
                  }}
                  {{
                    !tab_day
                      ? selectedEvent.tattooer.user.last_name
                      : selectedEvent.appointments
                      ? selectedEvent.appointments.customer.user.last_name
                      : selectedEvent.tattooer.user.last_name
                  }}
                </span>
              </v-toolbar-title>
              <v-spacer />
              <template>
                <v-btn
                  icon
                  class="mt-1"
                  @click="reserva(selectedEvent)"
                  small
                  v-if="
                    !selectedEvent.appointments &&
                      $store.getters['auth/isStudio']
                  "
                >
                  <v-icon size="15">$edit</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="mt-1"
                  @click="delReservation(selectedEvent)"
                  small
                >
                  <v-icon size="15">$delete</v-icon>
                </v-btn>
              </template>
              <v-btn
                icon
                @click="selectedOpen = false"
                style="position: absolute; right: 0; top: -10px"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="py-1">
              <p class="mb-2">
                <v-row>
                  <v-col cols="1" class="pt-1 pr-0 pr-md-5 py-0">
                    <v-icon class="pr-3" color="primary" size="30"
                      >$clock</v-icon
                    >
                  </v-col>
                  <v-col style="padding-top: 10px" class="pb-0">
                    {{ $d(new Date(selectedEvent.start_date), "date2digits") }}
                    | {{ selectedEvent.start_time }} -
                    {{ selectedEvent.end_time }}
                  </v-col>
                </v-row>
              </p>
              <p class="mb-2">
                <v-row>
                  <v-col cols="1" class="pr-5 py-0">
                    <v-icon class="pr-3" color="primary" size="30"
                      >$boxes</v-icon
                    >
                  </v-col>
                  <v-col style="padding-top: 6px" class="pb-0">
                    {{ selectedEvent.box_name || selectedEvent.boxes.name }}
                  </v-col>
                </v-row>
              </p>
              <p v-if="selectedEvent.appointments">
                <v-row>
                  <v-col cols="1" class="pr-5 py-0">
                    <v-icon class="pr-3 mt-2" color="primary">mdi-link</v-icon>
                  </v-col>
                  <v-col style="padding-top: 6px" class="pb-0">
                    <a
                      @click="
                        $router.push({
                          name: 'appointmentView',
                          params: { id: selectedEvent.appointments.id },
                        })
                      "
                      >{{ $tc("appointment") }}</a
                    >
                  </v-col>
                </v-row>
              </p>
              <p v-else>
                <v-row>
                  <v-col cols="1" class="pr-5 py-0">
                    <v-icon class="pr-3 mt-2" color="primary">mdi-link</v-icon>
                  </v-col>
                  <v-col style="padding-top: 6px" class="pb-0">
                    <a
                      @click="
                        $router.push({
                          name: 'tattooerView',
                          params: { id: selectedEvent.tattooer_id },
                        })
                      "
                      >{{ $tc("artist") }}</a
                    >
                  </v-col>
                </v-row>
              </p>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import * as moment from "moment";

import { debounce } from "lodash";

export default {
  name: "BoxList",
  watch: {
    options: {
      handler() {
        this.fetchBoxes();
      },
      deep: true,
    },
    optionsTattooer: {
      handler() {
        this.fetchBoxesReservation();
      },
      deep: true,
    },
  },
  computed: {
    can_reservate() {
      if (this.$store.getters["auth/isTattooer"]) {
        console.log(this.studio_id, this.studios_complete);
        let s = this.studios_complete.find((x) => {
          return x.studio_id === this.studio_id;
        });
        console.log("a", s);
        if (s && s.limitations) {
          return s.limitations.box;
        }
      }
      return true;
    },
    ...mapState("boxes", ["filtersState", "filtersWeekState", "boxState"]),
    filtersWeek: {
      get() {
        return this.filtersWeekState;
      },
      set(value) {
        this.$store.commit("boxes/SET_FILTERS_WEEK", value);
      },
    },
    filters: {
      get() {
        return this.filtersState;
      },
      set(value) {
        this.$store.commit("boxes/SET_FILTERS", value);
      },
    },

    box: {
      get() {
        return this.boxState;
      },
      set(value) {
        this.$store.commit("boxes/SET_BOX", value);
      },
    },
    dateError() {
      if (this.res.date && !this.allowedDates(this.res.date)) return true;
      return false;
    },
    horarioError() {
      if (this.res.date && this.res.start && this.res.end) {
        let dh = this.timeStudio[this.dayOfWeek(this.res.date)];
        if (this.res.tattooer) {
          let tt = this.tattooers_complete.find(
            (x) => x.tattooer_id === this.res.tattooer
          );
          if (tt.tattooer_timetable)
            dh = tt.tattooer_timetable[this.dayOfWeek(this.res.date)];
        }

        let error = true;
        dh.forEach((h) => {
          if (h[0] <= this.res.start && h[1] >= this.res.end) error = false;
        });
        return error;
      }
      return false;
    },
    ...mapState("boxes", ["tabs"]),
    studio_id: {
      get() {
        return this.box.studio_id;
      },
      set(value) {
        let studio = this.studios.find((x) => {
          return x.id == value;
        });
        console.log(studio);
        this.dates = studio.vacations;
        this.box.studio_id = value;
        this.filters.studio_id = value;
        this.filtersWeek.studio_id = value;
        this.filtersTattooer.studio_id = value;
        //console.log(value, studio);
        if (
          studio.user.setting.length <= 0 ||
          studio.user.setting[0].value == 0
        ) {
          this.noBox = true;
        } else {
          this.noBox = false;
          this.reload();
        }
      },
    },
    btn: {
      get() {
        return this.tabs.btn;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "btn" });
      },
    },
    tab: {
      get() {
        return this.tabs.tab;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "tab" });
      },
    },
    tab_day: {
      get() {
        return this.tabs.tab_day;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "tab_day" });
      },
    },
    tab_week: {
      get() {
        return this.tabs.tab_week;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "tab_week" });
      },
    },
    headers() {
      let array = [
        {
          text: this.$t("boxes.id"),
          align: "center",
          sortable: false,
          value: "order_by",
        },
        {
          text: this.$t("boxes.name"),
          align: "center",
          sortable: false,
          value: "name",
        },
      ];
      //payment active
      if (this.$store.getters["auth/getSetting"]("payment_box") == 1) {
        array.push({
          text: this.$t("boxes.token_payment"),
          align: "center",
          sortable: false,
          value: "token_payment",
        });
      }
      if (this.$store.getters["auth/getSetting"]("box_price") == 1) {
        array.push({
          text: this.$t("boxes.price"),
          align: "center",
          sortable: false,
          value: "price",
        });
      }

      array.push({
        text: this.$t("boxes.status"),
        align: "center",
        sortable: false,
        value: "status",
      });
      array.push({
        text: this.$t("boxes.income"),
        align: "center",
        sortable: false,
        value: "income",
      });
      //TATTOOER ASSOCIATE

      array.push({
        text: this.$t("boxes.tattooer_associate"),
        align: "center",
        sortable: false,
        value: "user",
      });

      array.push({
        text: this.$t("actions"),
        align: "center",
        sortable: false,
        value: "actions",
      });

      return array;
    },
    daysWeek() {
      let n_days = 7;
      let time = new Date(this.times.from);

      let arr = [];
      for (let i = 0; i < n_days; i++) {
        arr.push(time.toISOString().slice(0, 10));
        time.setDate(time.getDate() + 1);
      }

      return arr;
    },
    week: {
      get() {
        //return [];
        return [this.filters.from, this.filters.to];
      },
      set(value) {
        this.dates = [];
        value = value[0];
        let valor = value;
        this.dates.push(valor);
        value = new Date(value);
        value.setDate(value.getDate() + 1 - value.getDay());
        this.times.from = value.toISOString().slice(0, 10);

        value.setDate(value.getDate() + 6);
        this.times.to = value.toISOString().slice(0, 10);
      },
    },
    timeFrom() {
      if (this.timeStudio.length > 0) {
        let min = "23:00";
        let minFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[0];
            if (h <= min) {
              min = h;
            }
          });
        });
        return min;
        /*
        for (let i = 0; i < this.timeStudio.length; i++) {
          for (let k = 0; k < this.timeStudio[i].length; k++) {
            for (let q = 0; q < this.timeStudio[i][k].length; q++) {
              if (this.timeStudio[i][k][q] <= min) {
                min = this.timeStudio[i][k][q];
                minFinal = min;
              }
            }
          }
        }
        let final = minFinal.split(":");
        let fin = final[0];
        if (fin.indexOf("0") != -1) {
          fin = fin.substr(1, 1);
        }
        return fin;*/
      }
    },
    timeTo() {
      if (this.timeStudio.length > 0) {
        let max = "00:00";
        let maxFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[1];
            if (h >= max) {
              max = h;
            }
          });
        });
        return max;

        /*
        for (let i = 0; i < this.timeStudio.length; i++) {
          for (let k = 0; k < this.timeStudio[i].length; k++) {
            for (let q = 0; q < this.timeStudio[i][k].length; q++) {
              if (this.timeStudio[i][k][q] >= max) {
                max = this.timeStudio[i][k][q];
                maxFinal = max;
              }
            }
          }
        }
        let final = maxFinal.split(":");

        return final[0];*/
      }
    },
    hours() {
      if (this.timeStudio.length > 0) {
        let mins = [":00", ":30"];
        let hours = [];
        let first = parseInt(this.timeFrom.split(":")[0]);
        let last = parseInt(this.timeTo.split(":")[0]);
        for (let x = first; x < last; x++) {
          mins.forEach((y) => {
            if (x < 10) hours.push("0" + x + y);
            else hours.push(x + y);
          });
        }
        //console.log(hours);
        hours.push(last + ":00");
        if (this.timeTo.split(":")[1] == "30") hours.push(last + ":30");
        return hours;
      }
    },
    hoursRow() {
      if (this.timeStudio.length > 0) {
        let mins = [":00", ":30"];
        let hours = [];
        let first = parseInt(this.timeFrom.split(":")[0]);
        let last = parseInt(this.timeTo.split(":")[0]);
        for (let x = first; x < last; x++) {
          mins.forEach((y) => {
            if (x < 10) hours.push("0" + x + y);
            else hours.push(x + y);
          });
        }
        return hours;
      }
    },
    timeError() {
      if (this.res.start != undefined && this.res.end != undefined) {
        let s = this.res.start.split(":");
        let e = this.res.end.split(":");
        if (s[0] < e[0]) {
          return false;
        } else if (s[0] >= e[0]) {
          return true;
        }

        if (s[1] <= e[1]) {
          return false;
        }
        return true;
      }
      return false;
    },
    URL() {
      if (this.$store.getters["auth/isTattooer"])
        return `${window.location.origin}/?#/inscription/tattooer/${this.$store.state.auth.user.tattooer.token}`;
      if (this.$store.getters["auth/isStudio"])
        return `${window.location.origin}/?#/inscription/studio/${this.$store.state.auth.user.studio.token}`;
    },
  },
  data() {
    return {
      totalBoxes: 0,
      totalReservations: 0,
      dialogFilter: false,
      selectedElement: null,
      selectedOpen: false,
      selectedEvent: null,
      color: "var(--v-gris3-base)",
      menu: false,
      menu2: false,
      todayList: new Date().toISOString().substr(0, 10),
      newBox1: false,
      action: "save",
      actRes: false,
      times: {
        from: null,
        to: null,
      },
      boxWeek: [],
      boxWeekTotal: null,
      boxWeekHours: [],
      res: {
        id: null,
        date: null,
        start: null,
        end: null,
        tattooer: null,
        associate: false,
      },
      noBox: false,
      menu1: {
        month: false,
        day: false,
        week: false,
      },
      dates: [],
      datesTattooer: [],
      studios: [],
      studios_complete: [],

      dialog: false,
      resTat: false,
      resUpdate: false,
      resDialog: false,
      items: [],
      itemsTattooer: [],
      headersTattooer: [
        {
          text: this.$t("box"),
          align: "center",
          sortable: false,
          value: "boxes.name",
        },
        {
          text: this.$t("boxes.id"),
          align: "center",
          sortable: false,
          value: "boxes.order_by",
        },
        {
          text: this.$t("expenses.data"),
          align: "center",
          sortable: false,
          value: "data",
        },
        {
          text: this.$t("concept"),
          align: "center",
          sortable: false,
          value: "concept",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      tattooers: [],
      tattooers_complete: [],
      boxes: [],
      table: [],
      date: null,
      today: null,
      statusList: [],
      load: false,
      options: {},
      optionsTattooer: {},
      filtersTattooer: {},
      timeStudio: [],
    };
  },
  components: {
    BoxFilterCard: () => import("@/components/boxes/BoxFilterCard"),
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },

  mounted() {
    this.todayWeek();
    this.load = null;
    this.btn = this.$route.params.btn ? this.$route.params.btn : this.btn;
    this.today = new Date().toISOString().substr(0, 10);
    this.date = new Date().toISOString().substr(0, 10);

    if (this.$route.params.time) {
      this.btn = "day";
      this.date = this.$route.params.time;
    }

    this.fetchAll();
    if (this.$store.getters["auth/isStudio"]) {
      this.box.studio_id = this.$store.state.auth.user.studio.id;
      this.reload();
    } else if (this.$store.getters["auth/isTattooer"]) {
      this.tab_week = true;
      this.tab = true;
      this.fetchStudios();
    }
  },
  methods: {
    goToCalendar() {
      this.$router.push({ name: "calendar", params: { open: true } });
    },
    filterTattooer(tattooer_id) {
      if (!this.$store.getters["auth/isTattooer"]) {
        let t = this.tattooers.find((x) => x.id === tattooer_id);
        if (t) {
          this.filtersWeek.tattooer = tattooer_id;
          this.filterTable("tattooer");
        }
      }
    },
    reload() {
      //console.log("RELOADING");
      this.boxesHoursDay();
      this.fetchBoxes();
      this.fetchBoxesReservation();
      this.fetchTattooers();
      this.fetchTable();
      this.fetchWeek();
      this.fetchTimeStudio();
      this.fetchStatusList();
      if (this.filtersWeek.tattooer) {
        this.filterTable("tattooer");
      } else if (this.filtersWeek.box) {
        this.filterTable("box");
      }
    },
    allowedDates(date) {
      let a = this.dates.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (a) {
        console.log(a);
        return false;
      }
      let t = this.datesTattooer.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (t) {
        //console.log(t);
        return false;
      }

      let time = this.timeStudio;
      if (this.res.tattooer) {
        let tt = this.tattooers_complete.find(
          (x) => x.tattooer_id === this.res.tattooer
        );
        if (tt.tattooer_timetable) time = tt.tattooer_timetable;
      }

      if (time && time.length > 0) {
        let a = time[this.dayOfWeek(date)];
        if (a) {
          return a.length > 0;
        }
      }
      return false;
    },
    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },
    openBox() {
      this.box.name = null;
      this.box.price = null;
      this.box.token_payment = null;
      this.newBox1 = true;
      this.action = "save";
      setTimeout(() => {
        this.$refs.money_1.change();
        this.$refs.money_2.change();
      }, 500);
    },
    ...mapActions("tattooers", ["getTattooerStudiosByDate"]),
    fetchStudios() {
      this.getTattooerStudiosByDate({
        tattooer_id: this.$store.state.auth.user.tattooer.id,
        date: this.date,
      }).then((data) => {
        console.log(data);
        this.studios = data.map((x) => x.studio);
        this.studios_complete = data;
        if (data.length > 0) {
          let rel = data.find((rel) => {
            console.log(rel);
            //return true;
            return (
              rel.studio.user.setting.length > 0 &&
              rel.studio.user.setting[0].value == 1
            );
          });

          console.log("rel", rel);
          if (rel && !this.studio_id) this.studio_id = rel.studio.id;
          else data[0].studio.id;
        }
        this.reload();
      });
    },
    income(box) {
      return box.boxes_tattooer.reduce((acumulat_total, reserva) => {
        let acumulat_reserva_total = 0;
        if (reserva.appointments)
          acumulat_reserva_total = reserva.appointments.expenses.reduce(
            (acumulat_reserva, expense) => {
              if (expense.user_id === this.$store.state.auth.user.id)
                return acumulat_reserva + expense.cantidad;
              return acumulat_reserva;
            },
            0
          );
        console.log(reserva.appointments);

        return acumulat_total + acumulat_reserva_total;
      }, 0);
    },
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    fetchSetting() {
      this.loading = true;
      this.getSetting({ key: "box_active" }).then((boxes) => {
        //console.log(boxes.data);

        this.active = boxes.data.value;
        //console.log(this.active);
        this.loading = false;
      });
    },
    ...mapActions("boxes", [
      "getBoxes",
      "addBox",
      "updateBox",
      "deleteBox",
      "getTable",
      "showTablePercentage",
      "showWeekTableBox",
      "showWeekTableTattooer",
      "orderBox",
      "getBoxesReservations",
    ]),
    ...mapActions("studios", [
      "getActiveTattooer",
      "getStudioTattooersByDate",
      "getStudio",
    ]),
    ...mapActions("tattooers", [
      "reservationBox",
      "reservationBoxUpdate",
      "reservationBoxDelete",
      "editTattooerBox",
    ]),
    todayWeek() {
      let value = new Date();
      value.setDate(value.getDate() + 1 - value.getDay());
      this.times.from = value.toISOString().slice(0, 10);

      value.setDate(value.getDate() + 6);
      this.times.to = value.toISOString().slice(0, 10);
      //console.log(this.times.from, this.times.to);
      this.fetchWeek();
    },

    fetchTimeStudio() {
      this.getStudio(this.box.studio_id).then((data) => {
        this.timeStudio = data.timetable;
      });
    },
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.resDialog = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    async validateForm(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          if (this.action == "save") {
            this.saveBox();
          } else {
            this.update();
          }
        } else {
        }
      });
    },
    validateFormReserva(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        let timeVal = true;
        if (this.res.start >= this.res.end) {
          timeVal = false;
        }

        if (result && timeVal) {
          this.reservation();
        } else {
        }
      });
    },

    fetchBoxes() {
      this.loading = true;
      this.getBoxes({
        pagination: this.options,
        filters: this.filters,
      }).then((boxes) => {
        //console.log(this.load);
        this.totalBoxes = boxes.total;
        this.items = boxes.data;
        this.boxes = boxes.data;
        this.loading = false;
        if (this.totalBoxes === 0 && this.$store.getters["auth/isStudio"]) {
          this.tab = false;
        }
        this.load = true;
      });
    },
    fetchBoxesReservation() {
      this.getBoxesReservations({
        pagination: this.optionsTattooer,
        filters: this.filtersTattooer,
      }).then((boxes) => {
        console.log(boxes);
        this.totalReservations = boxes.total;
        this.itemsTattooer = boxes.data;
        console.log(this.itemsTattooer);
        this.load = true;
      });
    },
    fetchTattooers() {
      if (this.$store.getters["auth/isStudio"]) {
        this.loading = true;
        let date = new Date();
        if (this.res.date) {
          date = this.res.date;
        }
        this.getStudioTattooersByDate({
          studio_id: this.$store.state.auth.user.studio.id,
          date: date,
        }).then((data) => {
          this.tattooers = data.map((x) => x.tattooer);
          this.tattooers_complete = data;
          if (this.res.tattooer) {
            let a = this.tattooers.find((x) => x.id === this.res.tattooer);
            if (!a) {
              this.$alert(
                "El artista seleccionado no está activo para esta fecha"
              );
              this.res.tattooer = null;
            }
          }
          this.loading = false;
        });
      }
    },
    fetchTable() {
      this.loading = true;

      //console.log("%c%s", "color: #733d00", "date", this.date);
      this.getTable({ date: this.date, studio: this.box.studio_id }).then(
        (boxes) => {
          //console.log(boxes.box);
          this.items = boxes.box;
          this.table = boxes.box;
          this.loading = false;
        }
      );
    },
    fetchStatusList() {
      this.loading = true;

      this.getTable({ date: this.date, studio: this.box.studio_id }).then(
        (boxes) => {
          this.statusList = boxes.box;

          this.loading = false;
        }
      );
    },
    openDialog(h, date, i) {
      let end = this.hoursRow.filter((h) => h.indexOf(":30") === -1);
      this.$refs.BoxFilter.dialog = true;
      this.$refs.BoxFilter.date = date;
      this.res.start = h;
      let pos = i + 1;
      this.$refs.BoxFilter.fetchTimeStudio();
      this.res.end = end[pos];
    },
    fetchWeek() {
      this.loading = true;

      this.showTablePercentage({
        dates: this.times,
        studio: this.box.studio_id,
      }).then((boxes) => {
        this.boxWeek = boxes.data;
        this.boxWeekTotal = boxes.total;
        //console.log(this.boxWeek);

        if (this.$store.getters["auth/isStudio"]) {
          this.fetchAll();
        } else {
          let s = this.studios.find((x) => x.id == this.studio_id);
          this.dates = s.vacations;
          this.fetchAllVacationsTattooer("none", s.id);
        }
        this.loading = false;
      });
    },
    filterTable(value) {
      if (value === "tattooer") {
        this.filtersWeek.box = null;
        this.loading = true;
        this.times.tattooer_id = this.filtersWeek.tattooer;
        this.showWeekTableTattooer({
          dates: this.times,
          id_studio: this.box.studio_id,
        }).then((boxes) => {
          if (boxes) {
            this.boxWeekHours = boxes.data;

            this.loading = false;
          } else {
            this.boxWeekHours = null;
            this.loading = false;
          }
        });
      } else if (value === "box") {
        this.filtersWeek.tattooer = null;
        this.loading = true;

        this.showWeekTableBox({
          dates: this.times,
          id_box: this.filtersWeek.box,
        }).then((boxes) => {
          if (boxes) {
            this.boxWeekHours = boxes.data;

            this.loading = false;
          }
        });
      }

      //console.log(this.filtersWeek.tattooer + "" + this.filtersWeek.box);
    },
    tattooerHours(h, box) {
      let n = this.table.find((element) => element.id == box.id);
      // //console.log(h);
      if (n) {
        let hs = n.boxes_tattooer.find((x) => x.start_time == h);
        // //console.log(hs);
        if (hs) {
          let mins = [":00", ":30"];
          let hours = [];
          let end = parseInt(this.timeTo);
          let start = parseInt(this.timeFrom);
          for (let x = start; x <= end; x++) {
            mins.forEach((y) => {
              if (x < 10) hours.push("0" + x + y);
              else if (x == this.timeTo) {
                if (y.indexOf("3") == -1) {
                  hours.push(x + y);
                }
              } else hours.push(x + y);
            });
          }
          let span = 0;
          let s = 0;

          for (let i = 0; i < hours.length; i++) {
            if (hours[i] == hs.start_time) {
              s = i;
            } else if (hours[i] == hs.end_time) {
              span = i - s;
            }
          }

          return { reserva: hs, colspan: span, color: hs.tattooer.color };
        }
      }

      return { reserva: null, colspan: 1 };
    },
    tattooerHoursWeek(h, box, day) {
      let c = this.boxWeek[day];

      if (c) {
        let hs = c.find((x) => {
          return h == x.start_time && x.box_id == box.id && day == x.start_date;
        });
        // //console.log(hs);
        if (hs) {
          let mins = [":00", ":30"];
          let hours = [];
          let end = parseInt(this.timeTo);
          let start = parseInt(this.timeFrom);
          for (let x = start; x <= end; x++) {
            mins.forEach((y) => {
              if (x < 10) hours.push("0" + x + y);
              else if (x == this.timeTo) {
                if (y.indexOf("3") == -1) {
                  hours.push(x + y);
                }
              } else hours.push(x + y);
            });
          }
          let span = 0;
          let s = 0;

          for (let i = 0; i < hours.length; i++) {
            if (hours[i] == hs.start_time) {
              s = i;
            } else if (hours[i] == hs.end_time) {
              span = i - s;
            }
          }
          //console.log("box", hs);

          return { reserva: hs, colspan: span, color: hs.tattooer.color };
        }
      }

      return { reserva: null, colspan: 1 };
    },
    boxesWeekHours(h, day) {
      let c = null;
      if (this.boxWeekHours) c = this.boxWeekHours[day];
      if (c) {
        // //console.log(c);
        let hs = c.find((x) => x.start_time == h);

        if (hs) {
          let mins = [":00", ":30"];
          let hours = [];
          let end = parseInt(this.timeTo);
          let start = parseInt(this.timeFrom);
          for (let x = start; x <= end; x++) {
            mins.forEach((y) => {
              if (x < 10) hours.push("0" + x + y);
              else if (x == this.timeTo) {
                if (y.indexOf("3") == -1) {
                  hours.push(x + y);
                }
              } else hours.push(x + y);
            });
          }
          let span = 0;
          let s = 0;

          for (let i = 0; i < hours.length; i++) {
            if (hours[i] == hs.start_time) {
              s = i;
            } else if (hours[i] == hs.end_time) {
              span = i - s;
            }
          }

          return { reserva: hs, colspan: span, color: hs.tattooer.color };
        }
      }

      return { reserva: null, colspan: 1 };
    },
    tattooersWeekHours(h, day) {
      let c = null;
      if (this.boxWeekHours) c = this.boxWeekHours[day];
      if (c) {
        // //console.log(c);
        let hs = c.find((x) => x.start_time == h);

        if (hs) {
          let mins = [":00", ":30"];
          let hours = [];
          let end = parseInt(this.timeTo);
          let start = parseInt(this.timeFrom);
          for (let x = start; x <= end; x++) {
            mins.forEach((y) => {
              if (x < 10) hours.push("0" + x + y);
              else if (x == this.timeTo) {
                if (y.indexOf("3") == -1) {
                  hours.push(x + y);
                }
              } else hours.push(x + y);
            });
          }
          let span = 0;
          let s = 0;

          for (let i = 0; i < hours.length; i++) {
            if (hours[i] == hs.start_time) {
              s = i;
            } else if (hours[i] == hs.end_time) {
              span = i - s;
            }
          }

          return { reserva: hs, colspan: span, color: hs.tattooer.color };
        }
      }
      return { reserva: null, colspan: 1 };
    },

    boxesHoursDay(box) {
      let n = this.statusList.find((element) => element.id == box);

      if (n) {
        let hs = n.boxes_tattooer.filter((x) => x.start_date == this.date);

        if (hs) {
          //console.log(hs);

          let mins = [":00", ":30"];
          let hours = [];
          let end = parseInt(this.timeTo);
          let start = parseInt(this.timeFrom);
          for (let x = start; x <= end; x++) {
            mins.forEach((y) => {
              if (x < 10) hours.push("0" + x + y);
              else if (x == end) {
                if (y.indexOf("3") == -1) {
                  hours.push(x + y);
                }
              } else hours.push(x + y);
            });
          }
          let span = [];
          let s = 0;

          hs.filter((k) => {
            for (let i = 0; i < hours.length; i++) {
              if (hours[i] == k.start_time) {
                s = i;
              } else if (hours[i] == k.end_time) {
                span.push(i - s);
              }
            }
          });
          let p = null;
          for (let index = 0; index < span.length; index++) {
            p += span[index];
          }
          let percentage = p / 2 / (end - start);
          let hour = p / 2 - (end - start);
          //console.log(percentage);
          return { percentage: percentage, hour: Math.abs(hour) };
        }
      }
      return { percentage: null };
    },

    boxesHours(h, day) {
      let c = this.boxWeek[day];

      if (c) {
        let n = c.filter((x) => {
          return h >= x.start_time && h < x.end_time;
        });
        h = h.split(":")[0] + ":30";
        let n_2 = c.filter((x) => {
          return h >= x.start_time && h < x.end_time;
        });

        if (n && n_2) {
          let percentage = (n.length + n_2.length) / (this.boxWeekTotal * 2);
          let hours = n.length + n_2.length - this.boxWeekTotal * 2;

          return { reserva: percentage, hours: Math.abs(hours) / 2 };
        }
      }
      return { reserva: null, colspan: 1 };
    },
    rowspan(h, box) {
      let n = this.table.find((element) => element.id == box.id);
      if (n) {
        let hs = n.boxes_tattooer.find((x) => {
          return h > x.start_time && h < x.end_time;
        });
        ////console.log(n.boxes_tattooer[0].start_time == h);

        if (hs) {
          return false;
        }
      }
      return true;
    },
    rowspanTattooerWeek(h, box, day) {
      let c = this.boxWeek[day];
      if (c) {
        let hs = c.find((x) => {
          return h > x.start_time && h < x.end_time && x.box_id == box.id;
        });
        ////console.log(n.boxes_tattooer[0].start_time == h);

        if (hs) {
          return false;
        }
      }
      return true;
    },
    rowspanBox(h, day) {
      let c = null;
      if (this.boxWeekHours) c = this.boxWeekHours[day];
      if (c) {
        let hs = c.find((x) => {
          return h > x.start_time && h < x.end_time;
        });
        ////console.log(n.boxes_tattooer[0].start_time == h);

        if (hs) {
          return false;
        }
      }
      return true;
    },
    rowspanTattooer(h, day) {
      let c = null;
      if (this.boxWeekHours) c = this.boxWeekHours[day];
      if (c) {
        let hs = c.find((x) => {
          return h > x.start_time && h < x.end_time;
        });
        ////console.log(n.boxes_tattooer[0].start_time == h);

        if (hs) {
          return false;
        }
      }
      return true;
    },
    moveUp(value) {
      this.orderBox({ id: 1, order: value }).then((response) => {
        if (response) {
          this.errors.clear();
          this.reload();
        }
      });
    },
    moveDown(value) {
      this.orderBox({ id: 0, order: value }).then((response) => {
        if (response) {
          this.errors.clear();
          this.reload();
        }
      });
    },
    saveBox() {
      //console.log(this.box);
      this.addBox({
        box: this.box,
      }).then((response) => {
        if (response) {
          if (response.success) {
            this.errors.clear();
            this.$alert(this.$t("box_create_success"), "", "");
            this.reload();

            this.newBox1 = false;
            this.box.price = null;
            this.box.name = null;
            this.box.token_payment = null;
          } else {
            this.$alert(this.$t("box_" + response.error), "", "");
          }
        }
      });
    },
    edit(value) {
      this.newBox1 = true;
      this.action = "update";
      this.id_box = value.id;
      this.box.name = value.name;
      this.box.price = value.price;
      this.box.token_payment = value.token_payment;
    },
    reserva(h, box) {
      this.errors.clear();
      if (h == true) {
        this.resTat = false;
        this.dialog = true;
        this.res.start = null;
        this.res.tattooer = null;
        this.res.end = null;
        this.res.date = null;
        this.res.id = null;
        //console.log(this.res.date);
      }
      if (Number.isInteger(h)) {
        this.resTat = true;
        this.dialog = true;
        this.res.start = null;
        this.res.tattooer = null;
        this.res.end = null;
        this.res.date = moment().format("YYYY-MM-DD");
        this.res.id = h;
        //console.log(this.resTat);
      } else if (typeof h === "object") {
        this.resTat = false;
        this.dialog = true;
        this.res.start = h.start_time;
        this.res.tattooer = h.tattooer_id;
        this.res.end = h.end_time;
        this.res.date = h.start_date;
        this.res.id = h.box_id;
        this.resUpdate = true;
        this.res.reserva_id = h.reserva_id;
        console.log("%c%s", "color: #731d6d", this.res);
        console.log(h);
      } else if (this.$store.getters["auth/isStudio"]) {
        console.log("NEW?");
        this.resTat = false;
        this.dialog = true;
        this.res.start = h;
        this.res.tattooer = null;
        this.res.end = null;
        this.res.date = this.date;
        this.res.id = box.id;
      }

      //console.log(this.resTat);
      //console.log(this.res.date);
    },
    showEvent({ nativeEvent, event, box }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedEvent.box_name = box.name;
        //console.log(this.selectedEvent);
        if (nativeEvent) this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 100);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    ...mapActions("close_days", ["getAll"]),
    fetchAll() {
      this.getAll().then((data) => {
        if (data) {
          this.dates = data;
          console.log(this.dates);
        }
      });
    },
    ...mapActions("close_days_tattooer", ["getAllTattooer"]),
    fetchAllVacationsTattooer(tattooer, studio) {
      console.log(tattooer);
      this.getAllTattooer({ tattooer_id: tattooer, studio_id: studio }).then(
        (data) => {
          if (data) {
            this.datesTattooer = data;
            console.log(this.datesTattooer);
          }
        }
      );
    },
    checkTime(h, day) {
      let dh = this.timeStudio[this.dayOfWeek(day)];
      if (this.filtersWeek.tattooer) {
        let t = this.tattooers_complete.find(
          (x) => x.tattooer_id === this.filtersWeek.tattooer
        );
        if (t.tattooer_timetable)
          dh = t.tattooer_timetable[this.dayOfWeek(day)];
      }
      if (this.$store.getters["auth/isTattooer"]) {
        let t = this.studios_complete.find(
          (x) => x.studio_id === this.studio_id
        );
        if (t.tattooer_timetable)
          dh = t.tattooer_timetable[this.dayOfWeek(day)];
      }
      let error = true;

      let a = this.dates.find((x) => {
        return x.start_date <= day && x.end_date >= day;
      });

      if (a) {
        console.log(a);
        error = true;
        return error;
      }

      if (this.$store.getters["auth/isTattooer"]) {
        let t = this.datesTattooer.find((x) => {
          return x.start_date <= day && x.end_date >= day;
        });

        if (t) {
          error = true;
          return error;
        }
      }
      dh.forEach((ha) => {
        if (ha[0] <= h && ha[1] > h) error = false;
      });
      return error;
    },
    reservation() {
      //console.log(this.res.associate);
      if (this.res.associate) {
        this.editTattooerBox({
          tattooer_id: this.res.tattooer,
          formData: this.res,
        }).then((data) => {
          //console.log(data);
          this.res.associate = false;
        });
      }

      if (!this.resUpdate) {
        //console.log(this.res);
        this.reservationBox({
          tattooer_id: this.res.tattooer,
          formData: this.res,
        }).then((data) => {
          //console.log(data);
          if (data.count === 0) {
            this.$alert("Guardado correctamente", "", "");
            this.reload();

            this.dialog = false;
          } else {
            console.log(data);
            if (data.sentencia)
              this.$alert("El artista asignado está ocupado en este horario");
            else this.$alert("No hay disponibilidad para esta fecha", "", "");
          }
        });
      } else {
        //console.log(this.res);
        this.reservationBoxUpdate({
          tattooer_id: this.res.tattooer,
          formData: this.res,
        }).then((data) => {
          //console.log(data);
          if (data.sentencia == false) {
            this.$alert("Guardado correctamente", "", "");
            this.reload();

            this.dialog = false;
            this.resUpdate = false;
          } else {
            this.$alert("No hay disponibilidad para esta fecha", "", "");
          }
        });
      }
    },
    update() {
      //console.log(this.box);
      this.updateBox({
        box: this.box,
        id_box: this.id_box,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("box_edit_success"), "", "");
          this.reload();

          this.newBox1 = false;
          this.box.price = null;
          this.box.name = null;
          this.box.token_payment = null;
        }
      });
    },
    delBox(value) {
      this.$confirm(this.$t("are_you_sure_box"), "", "").then(() => {
        this.id_box = value.id;
        //console.log(this.id_box);
        this.deleteBox({
          id_box: this.id_box,
        }).then((response) => {
          if (response) {
            this.errors.clear();
            this.$alert(this.$t("box_delete_success"), "", "");
            this.reload();
          }
        });
      });
    },
    delReservation(value) {
      this.$confirm(
        value.appointments
          ? this.$t("are_you_sure_reserva_box")
          : this.$t("are_you_sure_reserva"),
        "",
        ""
      ).then(() => {
        this.id_reserva = value.reserva_id;
        //console.log(this.id_reserva);
        this.reservationBoxDelete({
          id_reserva: this.id_reserva,
        }).then((response) => {
          if (response) {
            this.errors.clear();
            this.$alert(this.$t("reserva_delete_success"), "", "");
            this.reload();

            this.selectedOpen = false;
          }
        });
      });
    },
    idBox(id) {
      console.log(this.boxes.find((x) => x.id == id));
      return this.boxes.find((x) => x.id == id);
    },
  },
};
</script>
<style lang="sass" scoped>
span.tattooer_name
td
  color: black !important
#tableBox td,
td:first-child span
  color: white !important
</style>
<style lang="sass">
#box-list
  #dataSimple
    td:last-child
      min-width: 150px
  .v-data-table__wrapper
    padding: 1px !important
  .daymenu
    .svg-icon
      color: var(--v-primary-base)
    fieldset
      height: 30px !important
    input
      margin-bottom: 7px
  #eventView
    .v-card__text
      font-size: 13px
      color: white !important
    p
      margin-bottom: 5px
  .v-select__slot
    color: var(--v-primary-base) !important
  .v-select__selections
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  .label-color .v-label, .v-select__slot .v-label, .v-input__slot .v-label
    color: var(--v-primary-base) !important
  .selected.selected.selected
    color: black !important
    border-color: var(--v-primary-base) !important
    background-color: var(--v-primary-base) !important
  .buttonBox
    display: flex
    justify-content: flex-end
  label
    display: block
    height: 25px
  .v-text-field.v-text-field--enclosed .v-text-field__details
    display: none
  .v-slide-group.v-item-group > .v-slide-group__next, .v-slide-group.v-item-group > .v-slide-group__prev
    display: none
  .color
    color: var(--v-primary-base) !important
  .filters .v-label
    color: var(--v-primary-base) !important
#tableBoxWeek
  min-width: 1000px
  padding-left: 40px
  width: auto
  background-color: var(--v-gris3-base)
  tr:first-child th
    border: 0
    font-size: 16px !important
  tr:nth-child(2) th
    border-top: 0
  td:first-child,th:first-child
    width: 50px
    position: absolute
  td:first-child, th:first-child
    border-left: 0
    border-top: 0
    border-right: 0
    min-width: 50px
  span
    display: block
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  td:first-child span
    position: absolute
    margin-top: -10px
    margin-left: -50px
  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  tr:last-child td:first-child
    border-bottom: 0
    span:first-child
      margin-top: -4px !important

  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)
    padding: 0 !important
    min-width: 100px
    border-color: var(--v-primary-base) !important

    max-width: 100px

    text-align: center
  td
    height: 25px
  td:nth-child(2), th:nth-child(2)
    min-width: 20px !important
    width: 20px

    border-left: 0
    border-bottom: 0
    border-top: 1px solid var(--v-primary-base)
#tableBox
  min-width: 1000px
  padding-left: 40px
  width: auto
  background-color: var(--v-gris3-base)
  tr:first-child th
    border: 0
    font-size: 16px !important
  tr:nth-child(2) th
    border-top: 0
  td:first-child,th:first-child
    width: 50px
  td:first-child, th:first-child
    border-left: 0
    border-top: 0
    border-right: 0
    min-width: 50px
    position: absolute
  span

    display: block
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  td:first-child span

    margin-top: -22px
    margin-left: -100px
  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  tr:last-child td:first-child
    border-bottom: 0
    span:first-child

  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)
    padding-right: 0 !important
    padding-left: 0 !important
    border-color: var(--v-primary-base) !important

    min-width: 100px
    max-width: 100px

    text-align: center
  td
    height: 50px
  td:nth-child(2), th:nth-child(2)
    min-width: 20px !important
    width: 20px

    border-left: 0
    border-bottom: 0
    border-top: 1px solid var(--v-primary-base)
#tableBoxDay
  margin-bottom: 20px !important
  min-width: 1000px
  padding-left: 40px
  width: auto
  background-color: var(--v-gris3-base)
  tr:first-child th
    border: 0
    min-width: 50px
    font-size: 16px !important
  tr:nth-child(2) th
    border: 0
  td:first-child, th:first-child
    border-left: 0
    border-top: 0
    min-width: 30px
    width: 50px
    position: absolute
  tr:last-child td:first-child
    border-bottom: 0
    span
      margin-top: -4px !important

  td
    height: 25px
    max-height: 25px

  span
    display: block
    &:noy(.tattooer_name)
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
    &.tattooer_name
      color: black
      &.single-line
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden

  td:first-child span
    position: absolute
    margin-top: -10px
    margin-left: -50px
  tr, td:first-child, th:first-child
    border-left: 0
    border-right: 0

  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)

    border-color: var(--v-primary-base) !important

    padding: 0 !important

    min-width: 120px
    max-width: 120px
    text-align: center
  th
    padding: 4px !important
  td:nth-child(2), th:nth-child(2)
    min-width: 20px !important
    width: 20px
    border-left: 0
    border-bottom: 0
    border-top: 1px solid var(--v-primary-base)
#tableTattooerWeek
  margin-bottom: 20px !important
  min-width: 1000px
  padding-left: 40px
  width: auto
  background-color: var(--v-gris3-base)
  tr:nth-child(2) th:nth-child(3)
    border-left: 1px solid var(--v-primary-base)
  tr:first-child th
    border: 0
    font-size: 16px !important
  tr:nth-child(2) th
    border: 0

  td:first-child, th:first-child
    border-left: 0
    border-top: 0
    border-right: 0
    min-width: 50px
  tr:last-child td:first-child
    border-bottom: 0
    span
      margin-top: -4px !important
  td:first-child,th:first-child
    width: 50px
    position: absolute
  td
    height: 25px
  span
    display: block
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  td:first-child span
    position: absolute
    margin-top: -10px
    margin-left: -50px
  tr, td:first-child, th:first-child
    border-left: 0
    border-right: 0

  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)
    border-color: var(--v-primary-base) !important

    padding: 0 !important

    min-width: 22px
    max-width: 22px
    text-align: center
  th
    padding: 4px !important
  td:nth-child(2), th:nth-child(2)
    min-width: 20px !important
    width: 20px
    border-left: 0
    border-bottom: 0
    border-top: 1px solid var(--v-primary-base)

#time
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer
    margin-top: 8px !important
.cusList
  height: 30px
  .v-list-item__icon
    margin-right: 5px !important

.cusList .v-icon, .cusList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)
.popupCard

  color: red !important
  width: 100% !important
  background-color: var(--v-gris3-base) !important
  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    .v-icon
      color: var(--v-primary-base) !important
      position: absolute

  .v-card__title
    background-color: var(--v-gris3-base)

#pedirReservaLink
  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    .v-icon
      color: var(--v-primary-base) !important
      position: absolute

@media (min-width: 800px)
  .shorter
    max-width: 201px
.base
  .v-input__control, .row .v-btn
    height: 30px !important
    margin-top: 5px

  label
    display: block
    height: 20px
.weekSelector
  .theme--dark.v-btn:hover::before
    opacity: 0

  tr
    &:hover
      td
        background-color: var(--v-primary-base)
        border-radius: 0
        border: 1px solid var(--v-primary-base)
        &:first-child
          border-top-left-radius: 50%
          border-bottom-left-radius: 50%
        &:last-child
          border-top-right-radius: 50%
          border-bottom-right-radius: 50%
        .v-btn__content
          color: black !important

    td
      border-radius: 50px

      &:first-child
        border-left: thin var(--v-primary-base) solid

      &:last-child
        border-right: thin var(--v-primary-base) solid
</style>
